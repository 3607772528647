import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import "../../assets/css/Home.css";
import {
	ab1,
	ab2,
	ab3,
	anm,
	arrow,
	arrow2,
	arrow3,
	HomeAbout,
	HomeAbout2,
	HomeContact,
	ht,
	icon1,
	icon2,
	icon3,
	icon4,
	icon5,
	pro1,
	pro2,
	pro3,
	videoImage,
	videoImage1,
	videoImage2,
} from "../../constant";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import HomeContactForm from "../../Components/Forms/HomeContactForm";
import Slider from "react-slick";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ActionButton from "../../Components/ActionBtn/ActionButton";

function Home() {
	const proData = [
		{
			id: 1,
			img: pro1,
		},
		{
			id: 2,
			img: pro2,
		},
		{
			id: 3,
			img: pro3,
		},
	];

	const proData2 = [
		{
			id: 1,
			img: videoImage,
		},
		{
			id: 2,
			img: videoImage1,
		},
		{
			id: 3,
			img: videoImage2,
		},
		{
			id: 4,
			img: videoImage1,
		},
	];
	var settings = {
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		pauseOnHover: true,
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<section className="home-banner">
				<div className="container">
					<div className="banner-cont">
						<h2 className="main-point animate__animated animate__backInDown">
							Mulching + Biodiversity + Education = Abundance{" "}
						</h2>
						<h3 className="main-title animate__animated animate__backInLeft">
							Holistic Homestead Farms
						</h3>
						<p className="para animate__animated animate__backInLeft">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore eu fugiat
							nulla pariatur. Excepteur sint occaecat cupidatat non proident.
						</p>
					</div>
				</div>
				{/* <div className="row">
					<div className="col-lg-2 col-md-4 col-sm-4 col-xs-2 home_absolute">
						
					</div>
				</div> */}
				<div className="btn-gr">
					<button className="btn book-btn btn_absolute ">
						BOOK NOW &nbsp;
						<img src={arrow2} className="image-fluid abcdefgh" alt="" />{" "}
					</button>
				</div>
			</section>
			<section className="home-about">
				<div className="container">
					<div>
						<h2 className="shadow-title">About</h2>
					</div>
					<div className="row">
						<div className="col-lg-3">
							<div className="img-cont">
								<img
									src={HomeAbout}
									alt=""
									className="image-fluid home-about-image abcdefgh"
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="home-about-section">
								<h3 className="about-title">Enjoy visiting our farm</h3>
								<p className="para">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Duis aute
									irure dolor in reprehenderit in voluptate velit esse cillum
									dolore eu fugiat nulla pariatur. Excepteur sint occaecat
									cupidatat non proident.
								</p>
								<p className="para2">
									At augue eget arcu dictum varius duis at consectetur. Molestie
									a iaculis at erat pellentesque adipiscing commodo elit at.
									Varius quam quisque id diam vel quam elementum. Sed
									ullamcorper morbi tincidunt ornare. Egestas purus viverra
									accumsan in nisl nisi scelerisque eu ultrices.{" "}
								</p>
							</div>
							<div className="btn-container margin_sett">
								<button className="btn BOOKNOW">
									Book Now
									<img src={arrow3} className="image-fluid ml-2 abcdefgh" />
								</button>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="img-cont margin_set margin_btm">
								<img
									src={HomeAbout2}
									alt=""
									className="image-fluid home-about-image2 abcdefgh"
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-4  margin_set2">
							<div className="img-cont img-data">
								<img
									src={ab2}
									alt=""
									className="image-fluid home-about-image3 abcdefgh"
								/>
							</div>
						</div>
						<div className="col-lg-4 margin_set2">
							<div className="img-cont img-data">
								<img
									src={ab1}
									alt=""
									className="image-fluid home-about-image4 abcdefgh"
								/>
							</div>
						</div>
						<div className="col-lg-4 margin_set2">
							<div className="img-cont img-data">
								<img
									src={ab3}
									alt=""
									className="image-fluid home-about-image5 abcdefgh"
								/>
							</div>
						</div>
					</div>
				</div>

				<section className="home-product">
					<div className="container">
						<div>
							<h2 className="shadow-title">Products</h2>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<h3 className="about-title">Browse Our Products</h3>
							</div>

							{proData?.map((item, index) => {
								return (
									<div className="col-lg-4 product-card" key={index}>
										<div>
											<img
												src={item?.img}
												alt=""
												className="image-fluid abcdefgh"
											/>
										</div>
										<div className="content">
											<p className="title">Whisper Coneflower</p>
											<p className="price">$250.00</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</section>

				<section className="home-social">
					<div className="container">
						<div>
							<h2 className="shadow-title">Social Media</h2>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<h3 className="about-title">Follow Us on Social Media</h3>
							</div>
							<div className="col-lg-12 ">
								<div className="all-icon">
									<div>
										<div className="icon-bg">
											<img src={icon1} alt="" className="image-fluid" />
										</div>
										<p className="icon-name">YOUTUBE</p>
									</div>

									<div>
										<div className="icon-bg">
											<img src={icon2} alt="" className="image-fluid" />
										</div>
										<p className="icon-name">TWITTER</p>
									</div>
									<div>
										<div className="icon-bg">
											<img src={icon3} alt="" className="image-fluid" />
										</div>
										<p className="icon-name">PINTEREST</p>
									</div>
								</div>
							</div>

							<div className="col-lg-12">
								<div className="all-icon2">
									<div>
										<div className="icon-bg">
											<img src={icon4} alt="" className="image-fluid" />
										</div>
										<p className="icon-name">INSTAGRAM</p>
									</div>
									<div>
										<div className="icon-bg">
											<img src={icon5} alt="" className="image-fluid" />
										</div>
										<p className="icon-name">FACEBOOK</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="home-post">
					<div className="container">
						<div className="row">
							<div className="col-lg-5 ">
								<div className="img-cont lecture_Img">
									<img
										src={ht}
										alt=""
										className="image-fluid home-post-image1 abcdefgh"
									/>
								</div>
							</div>
							<div className="col-lg-7">
								<div className="set_para_home">
									<h2 className="shadow-title">lectus quam</h2>
									<h3 className="main-title">Odio ut enim blandit volutpat </h3>
									<p className="para">
										Tempor orci dapibus ultrices in iaculis nunc sed. Nisl
										rhoncus mattis rhoncus urna neque. Vestibulum mattis
										ullamcorper velit sed ullamcorper. Penatibus et magnis dis
										parturient montes nascetur ridiculus mus mauris. Nisl vel
										pretium lectus quam id leo. Odio ut enim blandit volutpat
										maecenas volutpat blandit. Nunc vel risus commodo viverra
										maecenas. Lacus luctus accumsan tortor posuere ac ut
										consequat. Molestie nunc non blandit massa enim nec dui.{" "}
									</p>
									<p className="para">
										{" "}
										Nisl vel pretium lectus quam id leo. Odio ut enim blandit
										volutpat maecenas volutpat blandit. Nunc vel risus commodo
										viverra maecenas. Lacus luctus accumsan tortor posuere ac ut
										consequat. Molestie nunc non blandit massa enim nec dui.{" "}
									</p>
									<p className="para mt-2">
										Viverra maecenas. Lacus luctus accumsan tortor posuere ac ut
										consequat. Molestie nunc non blandit massa enim nec dui.{" "}
									</p>
								</div>
							</div>

							<div className="col-lg-7 make_margin">
								<div className="lectus-quam lectuus_wewe set_para_home2">
									<h2 className="shadow-title">lectus quam</h2>
									<h3 className="main-title">Odio ut enim blandit volutpat </h3>
									<p className="para">
										Tempor orci dapibus ultrices in iaculis nunc sed. Nisl
										rhoncus mattis rhoncus urna neque. Vestibulum mattis
										ullamcorper velit sed ullamcorper. Penatibus et magnis dis
										parturient montes nascetur ridiculus mus mauris. Nisl vel
										pretium lectus quam id leo. Odio ut enim blandit volutpat
										maecenas volutpat blandit. Nunc vel risus commodo viverra
										maecenas. Lacus luctus accumsan tortor posuere ac ut
										consequat. Molestie nunc non blandit massa enim nec dui.{" "}
									</p>
									<ul className="unit_link_set">
										<li className="para-points">
											<CheckCircleIcon style={{ width: "16px" }} /> &nbsp;&nbsp;
											Tempor orci dapibus ultrices in iaculis nunc sed.
										</li>
										<li className="para-points">
											<CheckCircleIcon style={{ width: "16px" }} /> &nbsp;
											&nbsp; Nisl rhoncus mattis rhoncus urna neque.
										</li>
										<li className="para-points">
											<CheckCircleIcon style={{ width: "16px" }} /> &nbsp;&nbsp;
											Vestibulum mattis ullamcorper velit sed ullamcorper.{" "}
										</li>
										<li className="para-points">
											<CheckCircleIcon style={{ width: "16px" }} />{" "}
											&nbsp;&nbsp;&nbsp;Penatibus et magnis dis parturient
											montes nascetur ridiculus
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-5 set_margin">
								<div className="img-cont lecture_Img">
									<img
										src={anm}
										alt=""
										className="image-fluid home-post-image1 abcdefgh"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section
					className="home-video"
					style={{ zIndex: "9", position: "relative" }}
				>
					<div className="container">
						<div>
							<h2 className="shadow-title">Our Farm</h2>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<h3 className="about-title">Have a look At our Farm</h3>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="ourfarm_carousel">
									<Slider {...settings}>
										{proData2.map((item, index) => {
											return (
												<img
													src={item.img}
													alt="allImages"
													className="image-fuild abcdefgh"
												/>
											);
										})}
									</Slider>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section
					className="home-newsLetter"
					style={{ zIndex: "9", position: "relative" }}
				>
					<div className="container newsLetter-align">
						<div className="row">
							<div className="col-lg-6">
								<p className="main-title">Subscribe to Our Newsletter</p>
							</div>
							<div className="col-lg-4">
								<input
									className="newletter-input"
									type="text"
									placeholder="Write your Email"
								/>
							</div>
							<div className="col-lg-2">
								<button className="btn book-btn">
									Submit
									<img
										src={arrow2}
										className="image-fluid abcdefgh"
										alt=""
									/>{" "}
								</button>
							</div>
						</div>
					</div>
				</section>

				<HomeContactForm />
			</section>
			<ActionButton />

			<Footer />
		</>
	);
}

export default Home;
