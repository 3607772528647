import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { map, plant1, v1 } from "../../constant";

function FrequentlyCard(props) {
	const { select, setSelect } = props;

	const cardData = [
		{
			id: 1,
			img: plant1,
		},
		{
			id: 2,
			img: plant1,
		},
		{
			id: 3,
			img: plant1,
		},
		{
			id: 4,
			img: plant1,
		},
	];

	const HandleSelect = (id) => {
		// Already selected unselect
		if (select.includes(id)) {
			setSelect(select.filter((item) => item !== id));
			return;
		} else {
			setSelect([...select, id]);
		}
	};

	return (
		<>
			{cardData.map((item, index) => {
				return (
					<>
						<div
							className="col-lg-3 CardRelative"
							key={index}
							onClick={() => HandleSelect(item.id)}
						>
							<Link to="#">
								<div
									className={`${
										select.includes(item.id) ? "BorderCard p-card" : "p-card"
									}`}
								>
									<div className="img-cont">
										<img src={item?.img} alt="" className="image-fluid" />
									</div>
									<div className="content">
										<p className="title">Whisper Coneflower</p>
										<p className="title2">$250.00</p>
									</div>
								</div>
							</Link>
							<span className="plusSign">+</span>
						</div>
					</>
				);
			})}
		</>
	);
}

export default FrequentlyCard;
