import React from "react";
import Header from "../../Components/Header/Header";
import "../../assets/css/Login.css";
import { Link } from "react-router-dom";
import { arrow } from "../../constant";

function Signup() {
	return (
		<>
			<Header />
			<section className="login">
				<div className="login-card">
					<div className="main-title-cont">
						<hr className="hr-line" />
						<h2 className="main-title">Sign Up</h2>
						<hr className="hr-line" />
					</div>
					<p className="inner-title">
						Welcome to <span>Hoho Farms</span>
					</p>
					<div className="regs-cont">
						<p className="regs-text">Already a Member ?</p>
						<p className="regs-text">
							<Link to="/login">Sign In</Link>
						</p>
					</div>
					<div className="form-cont row">
						<div className="col-lg-6 mb-3">
							<label>First Name</label>
							<input type="text" className="input-custom" />
						</div>
						<div className="col-lg-6 mb-3">
							<label>Last Name</label>
							<input type="text" className="input-custom" />
						</div>
						<div className="col-lg-6 ">
							<label>Email Address</label>
							<input type="text" className="input-custom" />
						</div>
						<div className="col-lg-6 ">
							<label>Contact Number</label>
							<input type="text" className="input-custom" />
						</div>
						<div className="col-lg-6 ">
							<label>Password</label>
							<input type="text" className="input-custom" />
						</div>
						<div className="col-lg-6 ">
							<label>Confirm Password</label>
							<input type="text" className="input-custom" />
						</div>

						<div className="btn-cont mt-4">
							<button className="btn loginBtn">
								SIGN UP <img src={arrow} alt="" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Signup;
