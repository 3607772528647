import React, { useState } from "react";
import "../../assets/css/Checkout.css";
import { cart1, visa } from "../../constant";
import MainBanner from "../../Components/Banner/MainBanner";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import StripeForm from "../../Components/PaymentMethod/StripeForm";
import { useNavigate } from "react-router-dom";

function Checkout() {
	const navigate = useNavigate();
	const [addnewAddress, setAddnewAddress] = useState(false);
	const cartData = [
		{
			id: 1,
		},
		{
			id: 2,
		},
		{
			id: 3,
		},
	];

	const HandleShowAddress = () => {
		setAddnewAddress(!addnewAddress);
	};
	return (
		<>
			<Header />
			<MainBanner name={"Check Out"} />

			{/* <!-- checkout-section starts here --> */}
			<section className="checkout-page">
				<div className="container">
					<form action="thank-you.php">
						<div className="row">
							<div className="col-lg-6">
								<div className="first-top-bar">
									<h3>Shipment Details</h3>
									{/* <!-- <p>Already Have an account? <a href="login.php">Log in</a> </p> --> */}
								</div>

								<div className="saved-address">
									<div className="title">
										<h5>Use Saved Addresses</h5>
									</div>

									<label className="container">
										<input type="radio" checked="checked" name="radio" />
										<span className="checkmark">
											<div className="select-address">
												<div className="selection">
													<i className="fa fa-check" aria-hidden="true"></i>
												</div>
												<div className="radio-btn">
													<span></span>
												</div>
												<div className="address-detail">
													<h5>
														<span className="property">Country:</span>
														<span className="value">United States</span>
														<span className="property pl-4">State:</span>
														<span className="value">Texas</span>
													</h5>
													<h5>
														<span className="property">Address:</span>
														<span className="value">
															10515 Fox Ave Fairdale, Kentucky(KY), 40118
														</span>
													</h5>
													<h5>
														<span className="property">Phone:</span>
														<span className="value">+44-123-456-789</span>
													</h5>
													<button className="btn default disabled">
														Default
													</button>
												</div>
											</div>
										</span>
									</label>

									<label className="container">
										<input type="radio" name="radio" />
										<span className="checkmark">
											<div className="select-address">
												<div className="selection">
													<i className="fa fa-check" aria-hidden="true"></i>
												</div>
												<div className="radio-btn">
													<span></span>
												</div>
												<div className="address-detail">
													<h5>
														<span className="property">Country:</span>
														<span className="value">United States</span>
														<span className="property pl-4">State:</span>
														<span className="value">Texas</span>
													</h5>
													<h5>
														<span className="property">Address:</span>
														<span className="value">
															10515 Fox Ave Fairdale, Kentucky(KY), 40118
														</span>
													</h5>
													<h5>
														<span className="property">Phone:</span>
														<span className="value">+44-123-456-789</span>
													</h5>
												</div>
											</div>
										</span>
									</label>
								</div>
								<div className="add-new-address">
									<div className="form-check py-3 margin-est">
										<input
											type="checkbox"
											className="form-check-input ana"
											onClick={HandleShowAddress}
										/>
										<label className="form-check-label label_ana">
											Add New Address
										</label>
									</div>
									{/* <div className="form-group mb-4">
										<label for="method">Shipment Method</label>
										<select id="method" className="form-control">
											<option selected="">Select Shipment method</option>
											<option>Select Shipment method 1</option>
											<option>Select Shipment method 2</option>
											<option>Select Shipment method 3</option>
										</select>
									</div> */}
									{addnewAddress ? (
										<div className="row">
											<div className="col-lg-6">
												<div className="form-group">
													<label for="fname">First Name</label>
													<input
														type="text"
														id="fname"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<label for="lname">Last Name</label>
													<input
														type="text"
														id="lname"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<label for="phone">Phone</label>
													<input
														type="text"
														id="phone"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<label for="email">Email</label>
													<input
														type="text"
														id="email"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label for="address">Address Line 1</label>
													<input
														type="text"
														id="address"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label for="address">Address Line 2</label>
													<input
														type="text"
														id="address"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label for="apartmnt">Suite, Apartment, etc </label>
													<input
														type="text"
														id="apartmnt"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label for="city">City</label>
													<input
														type="text"
														id="city"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label for="region">Country</label>
													<input
														type="text"
														id="region"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label for="state">State</label>
													<input
														type="text"
														id="state"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label for="zip-code">Zip Code</label>
													<input
														type="text"
														id="zip-code"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-12 margin-est">
												<div className="form-check">
													<input type="checkbox" className="form-check-input" />
													<label className="form-check-label label_ssd">
														Save Shipping Details
													</label>
												</div>
											</div>
										</div>
									) : null}
								</div>
								<div className="payment-details">
									<div className="heading">
										<h3>Payment Details</h3>
									</div>
									<div className="saved-payment-method">
										{/* <h5>Use Saved Payments</h5> */}
										{/* <label className="container">
											<input
												type="radio"
												checked="checked"
												name="payment-radio"
											/>
											<span className="checkmark">
												<div className="card-details">
													<div className="radio-btn">
														<span></span>
													</div>
													<div className="visa-card">
														<div className="img-box">
															<figure>
																<img src={visa} alt="No Image Found" />
															</figure>
														</div>
														<div className="input-box">
															<label for="card-number">My Personal Card</label>
															<p className="card-number-input">
																**********1239
															</p>
														</div>
													</div>
													<div className="extra-btn">
														<button className="btn default disabled">
															Default
														</button>
														<div className="selection">
															<i className="fa fa-check" aria-hidden="true"></i>
														</div>
													</div>
												</div>
											</span>
										</label> */}

										{/* <label className="container second-card">
											<input type="radio" name="payment-radio" />
											<span className="checkmark">
												<div className="card-details">
													<div className="radio-btn">
														<span></span>
													</div>
													<div className="visa-card">
														<div className="img-box">
															<figure>
																<img src={visa} alt="No Image Found" />
															</figure>
														</div>
														<div className="input-box">
															<label for="card-number">My Personal Card</label>
															<p className="card-number-input">
																**********1239
															</p>
															
														</div>
													</div>
													<div className="extra-btn">
														<button className="btn default disabled">
															Default
														</button>
														<div className="selection">
															<i className="fa fa-check" aria-hidden="true"></i>
														</div>
													</div>
												</div>
											</span>
										</label> */}
										{/* <div className="col-md-12 mt-5">
											<div className="form-check">
												<input type="checkbox" className="form-check-input" />
												<label className="form-check-label label_ssd">
													Add New Payment
												</label>
											</div>
										</div> */}
										<StripeForm paynow="Payment" />
									</div>
								</div>
							</div>
							<div className="col-lg-1"></div>
							<div className="col-lg-5">
								<div className="second-top-bar">
									<div className="title pb-4">
										<h3>Order Summary</h3>
									</div>
									{/* <label for="Enter Voucher Code : ">Enter Voucher Code : </label> */}
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											placeholder="Enter Promo Code"
										/>
										<button type="button">
											APPLY <img src="img/right-arrow.svg" alt="" />
										</button>
									</div>
								</div>
								<div className="cart-product-detail">
									<div className="title">
										<h3>Products Details</h3>
									</div>
									<div className="checkout-products ">
										{cartData?.map((item, index) => {
											return (
												<div className="products br-right" key={index}>
													<div className="abt-product">
														<div className="img-box">
															<figure>
																<img src={cart1} alt="123" />
															</figure>
														</div>
														<div className="pro-dtl">
															<h5 className="name">Whisper Coneflower</h5>
															<ul className="variations">
																<li>Color: Green</li>
															</ul>
															<ul className="variations">
																<li>Leaf Type: Lorem</li>
															</ul>
														</div>
													</div>
													<div className="product-price">
														<h4 className="price">$539.00</h4>
													</div>
												</div>
											);
										})}
									</div>
									<div className="pricing-details">
										<div className="item-total">
											<h4 className="property">Item Total :</h4>
											<h4 className="value">$50</h4>
										</div>
										<div className="item-total">
											<h4 className="property">Shipment & Delivery :</h4>
											<h4 className="value">$0</h4>
										</div>
										<div className="item-total">
											<h4 className="property">Promo Applied :</h4>
											<h4 className="value">-$5</h4>
										</div>
									</div>

									<div className="order-total">
										<h3 className="property">Order Total</h3>
										<h3 className="value">$3025.00</h3>
									</div>
								</div>
							</div>
						</div>
						<div className="border-last"></div>
						<div className="confirm-orderDv">
							<div className="checkbox">
								<input
									type="checkbox"
									className="form-control"
									id="for_checkbox"
								/>
								<label for="for_checkbox" className="for_checkbox_label">
									Proident, sunt in culpa qui officia deserunt mollit anim id
									est laborum.
								</label>
							</div>
							<div className="button-group">
								<button type="submit" onClick={() => navigate("/thankyou")}>
									Confirm Order <img src="img/right-arrow.svg" alt="" />
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
			{/* <!-- checkout-section ends here --> */}

			<Footer />
		</>
	);
}

export default Checkout;
