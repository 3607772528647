import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../../assets/css/OrderDetails.css";

// yeh page hai
function OrderDetails() {
	const navigate = useNavigate();
	// create event
	const Navigate = useNavigate();

	const data = [
		{
			id: 1,
		},
		{
			id: 2,
		},
		{
			id: 3,
		},
	];
	return (
		<>
			<Header />
			<section className="plantingMixing-page">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="main_order_details">
								<div className="first_section">
									<h2>Orders Details</h2>
									<h5>#1084598423154</h5>
								</div>
								<div className="order_details_box">
									<div className="first_section_product">
										<div className="first_section_heading">
											<h6>Product Detail</h6>
										</div>
									</div>
									<div className="second_section_product">
										<div className="second_section_heading">
											<h6>Order Detail</h6>
										</div>
									</div>
									<div className="third_section_product">
										<div className="third_section_heading">
											<h6>Shipment</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default OrderDetails;
