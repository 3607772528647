import React from "react";
import { Rating } from "react-simple-star-rating";
import { arrow, user } from "../../constant";

function ReviewBox(props) {
	const { handleRating, rating } = props;
	return (
		<>
			<section className="leave_comment">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<form action="">
								<div className="row">
									<div className="col-lg-6 review-user-send mb-3 mt-3">
										<div className="user-imgCont">
											<img src={user} alt="" className="image-fluid" />
										</div>
										<div>
											{" "}
											<p className="user-name">Barry Allen</p>
											<p className="user-about">
												Your review will be Posted on Web
											</p>
										</div>
									</div>
									<div className="col-lg-6 mb-3 mt-3">
										<div className="form-group post-star-cont">
											<Rating onClick={handleRating} ratingValue={rating} />
										</div>
									</div>

									<div className="col-md-12">
										<div className="form-group">
											<textarea
												className="form-control"
												placeholder="Write Your Review Here"
											></textarea>
										</div>
									</div>
									<div className="col-lg-6">
										<div>
											<button className="btn SubmitReview">
												Submit review{" "}
												<img src={arrow} alt="" className="image-fluid" />
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className="col-md-4"></div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ReviewBox;
