import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/Profile.css";
import SideBar from "../../Components/SideBar/SideBar";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../../Components/SideBar/TopBar";
import { arrow, ProBox } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Signup from "./../Auth/Signup";

function CSAmemberdetail() {
	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState("");
	const [uploadLoading, setUploadLoading] = useState(false);
	const [editState, setEditState] = useState(false);
	const [passLoading, setPassLoading] = useState(false);

	const [firstName, setFirstName] = useState("John");
	const [lastName, setLastName] = useState("Doe");
	const [phone, setPhone] = useState("0123432432");
	const [editLoading, setEditLoading] = useState(false);

	const [currentPassword, setCurrentPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [passwordChange, setPasswordChange] = useState(false);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const handleImageUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		setUploadLoading(true);
	};

	const EditProfile = (e) => {
		e.preventDefault();
	};

	const changePassword = (e) => {
		e.preventDefault();
	};

	return (
		<>
			<Header />

			{/* <!-- Universal Banner Sec End Here --> */}
			<section className="profile">
				<div className="container">
					<div className="row profile-row">
						<SideBar
							uploadLoading={uploadLoading}
							fileupload={fileupload}
							handleImageUpload={handleImageUpload}
						/>
						<div className="col-lg-9">
							<div className="tab-content" id="v-pills-tabContent">
								<div
									className="tab-pane fade active show"
									id="pills-home"
									role="tabpanel"
									aria-labelledby="pills-home-tab"
								>
									<TopBar />
									<div className="row"></div>
									<div className="row info-profile">
										<div className="col-md-12 rightSide-profile">
											<div className="row mb-4">
												<div className="col-lg-6">
													<div className="Accounts">
														<h5>My MemberShip</h5>
													</div>
													<p className="familyMember-Title">CSA Membership</p>
													<p className="expiryBtn">
														Purchase date: 29 dec 2022 | Expiry date: 29 Jan
														2023
													</p>{" "}
													<p className="expiryBtn">
														Renewal date : 29 Jan 2023
													</p>
												</div>
												<div className="col-lg-6">
													<div className="familyMember-Title">
														<button className="btn cancel-memberBtn3">
															Update to Bi weekly &nbsp;&nbsp;
															<img src={arrow} alt="" className="image-fluid" />
														</button>
														<button className="btn cancel-memberBtn cancel-memberBtn4">
															Update to Monthly &nbsp;&nbsp;
															<img src={arrow} alt="" className="image-fluid" />
														</button>
														<button className="btn cancel-memberBtn cancel-memberBtn5">
															Cancel Membership &nbsp;&nbsp;
															<img src={arrow} alt="" className="image-fluid" />
														</button>
													</div>
												</div>
											</div>
											<hr />
											<div className="row csa_Membership">
												<div className="productDetails_section">
													<div className="heading_div">
														<h6>Product</h6>
													</div>
													<div className="productDiv">
														<img src={ProBox} alt="pro-image" />
														<div className="productDiv_text">
															<h4>Product box 1</h4>
															<p>This box includes</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default CSAmemberdetail;
