import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/OrderDetail.css";
import OrderDetailSlider from "../../Components/Slider/OrderDetailSlider";

function OrdersDetails() {
	return (
		<>
			<Header />
			<MainBanner name={"Orders Details"} />
			<section className="ordersdetails-page">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<OrderDetailSlider />
						</div>
						<div className="col-lg-6">
							<div>
								<p className="maples">Maples</p>
								<h3 className="productName">Whisper Coneflower</h3>

								<p className="desc-title">Description</p>
								<p className="productDesc">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Duis aute
									irure dolor in reprehenderit in voluptate velit esse cillum
									dolore eu fugiat nulla pariatur. Excepteur sint occaecat
									cupidatat non proident.
								</p>

								<p className="desc-title mt-4 mb-4">Additional Information </p>
								<div className="row main-row-div">
									<div className="col-lg-6 firstDesc">
										<p className="productDesc">Latin Name: Lorem ipsum</p>
										<p className="productDesc">Common Name: Lorem ipsum</p>
										<p className="productDesc">Sun Exposure: Lorem ipsum</p>
										<p className="productDesc">Annual Growth: Lorem ipsum</p>
										<p className="productDesc">HxW @10 Years: Lorem ipsum</p>
									</div>
									<div className="col-lg-6">
										<p className="productDesc">Color: Lorem ipsum</p>
										<p className="productDesc">Leaf Type: Lorem ipsum</p>
										<p className="productDesc">Growth Rate: Lorem ipsum</p>
										<p className="productDesc">Hardiness Zone: Lorem ipsum</p>
									</div>
								</div>
								<br />
								<div className="size-select-div">
									<div className="row">
										<div className="col-lg-6">
											<p>
												Selected Size : <span>Medium</span>
											</p>
										</div>
										<div className="col-lg-6">
											<p>
												Select Quantity : <span className="counter">03</span>
											</p>
										</div>
										<div className="col-lg-6">
											<p>
												Selected Add On : <span>Lorem</span>
											</p>
										</div>
										<hr style={{ color: "#707070" }} />
									</div>
								</div>

								<div className="size-select-div">
									<div className="row">
										<div className="col-lg-6">
											<p>
												Order ID : <span>#1084598423154</span>
											</p>
										</div>
										<div className="col-lg-5">
											<p>
												Status : <span>confirm</span>
											</p>
										</div>
										<div className="col-lg-6">
											<p>
												Order: <span>04-12-21</span>
											</p>
										</div>

										<div className="col-lg-6">
											<p>
												Payment Status:{" "}
												<span className="PaidStatus">
													Paid{" "}
													<i class="fa fa-check-circle" aria-hidden="true"></i>
												</span>
											</p>
										</div>
									</div>
								</div>

								<div className="mt-4 mb-4">
									<button type="button" className="btn AddBtn">
										BACK TO MY ORDERS
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default OrdersDetails;
