import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Booking.css";
import { n1, n2, n3, n4 } from "../../constant";
import ProductCard from "../../Components/Cards/ProductCard";
import ReactPaginate from "react-paginate";

function BookingCard(props) {
	let { mainhead, mainnumber, smallhead, details, payDetails } = props;
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(10);

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	console.log(payDetails);
	return (
		<>
			<Header />
			<section className="plantingMixing-page">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="detail_amount_total">
								<div className="main_heading">
									<h2 className="main_title">{mainhead}</h2>
									<h4 className="main_number">{mainnumber}</h4>
								</div>
								<div className="card_details">
									<div className="details_head">{smallhead}</div>
									{/* <div className="row">
										<div className="col-lg-6 book_details">
											<p>Booking Date:</p>
											<p>Booking Time</p>
											<p>Payment Status</p>
											<p>Total</p>
										</div>
										<div className="col-lg-4">
											<p>17:30 Am</p>
											<p>29 Dec 22</p>
											<p>Paid</p>
											<p>$50.00</p>
										</div>
										<div className="col-lg-2"></div>
									</div> */}
									<div className="all_booking_details mt-4">
										<ul>
											<li>
												<span>{details.Paymenttime}</span>
												<span>{payDetails.Paymentdate}</span>
											</li>
											<li>
												<span>{details.Paymentdate}</span>
												<span>{payDetails.Paymenttime}</span>
											</li>
											<li>
												<span>{details.PaymentStatus}</span>
												<span>{payDetails.PaymentStatus}</span>
											</li>
											{details.Campaign && (
												<li>
													<span>{details.Campaign}</span>
													<span>{payDetails?.Campaign}</span>
												</li>
											)}
											<li>
												<span>{details.Total}</span>
												<span>{payDetails.Total}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default BookingCard;
