import React from 'react'

function MainBanner(props) {
    const {name} = props;
  return (
   <>
    <section className="All_banner">
        <div className="container">
            <div className='main-cont'>
                <h2 className='main-title'>{name}</h2>
            </div>
        </div>
      </section>
   </>
  )
}

export default MainBanner