import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../../assets/css/DonatedListing.css";
import ChangingProgressProvider from "./ChangingProgressProvider";
import { CardImage1, CardImage2 } from "../../constant";
import Header from "../Header/Header";
import MainBanner from "../Banner/MainBanner";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

function DonateListing() {
	const percentage = 66;
	const navigate = useNavigate();

	const handlePage = () => {
		navigate("/our-doners");
	};
	return (
		<>
			<Header />
			<MainBanner name={"Campaigns"} />
			<section className="Donate-listing">
				<div class="container">
					<div class="row">
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage1} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p>47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage2} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p>47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage1} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p>47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage1} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p>47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage2} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p>47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage1} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p className="mb-0">47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default DonateListing;
