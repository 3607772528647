import React from "react";
import QRCode from "qrcode.react";
function QAScanner() {
	return (
		<QRCode
			id="qr-gen"
			size={150}
			level={"H"}
			includeMargin={false}
			style={{ margin: "20px 0px" }}
		/>
	);
}

export default QAScanner;
