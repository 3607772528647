import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { handbag, shirt, shp } from "../../constant";
import "../../assets/css/AllPlants.css";
import ProductCard from "../../Components/Cards/ProductCard";
import ReactPaginate from "react-paginate";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import AllPlantsCard from "./AllPlantsCard";

function Products() {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(10);

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};
	const plantData = [
		{
			id: 1,
			img: shp,
		},
		{
			id: 2,
			img: handbag,
		},
		{
			id: 3,
			img: shirt,
		},
		{
			id: 4,
			img: shp,
		},
		{
			id: 5,
			img: handbag,
		},
		{
			id: 6,
			img: shirt,
		},
	];

	const cateData = [
		{
			id: 1,
		},
		{
			id: 2,
		},
		{
			id: 3,
		},
		{
			id: 4,
		},
	];
	return (
		<>
			<Header />
			<MainBanner name={"Products"} />
			<section className="allPlants-page">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="filter-side">
								<div className="title-cont">
									<h2 className="filter-title">Apply Filters</h2>
								</div>
								<div className="search-bar-cont">
									<input type="text" placeholder="Serach" className="search" />
								</div>
								<p className="maincate-head">Category</p>
								{cateData?.map((item, index) => {
									return (
										<div className="cate-box" key={index}>
											<p className="title">Lorem</p>
											<input type="checkbox" value="Bike"></input>
										</div>
									);
								})}

								<ActionButton />
								<p className="load-more">LOAD MORE</p>

								<p className="maincate-head">Price</p>
								<div className="row">
									<div className="col-lg-6">
										<label className="price-text">From</label>
										<input
											type="number"
											className="price-filter"
											placeholder="1"
										/>
									</div>
									<div className="col-lg-6">
										<label className="price-text">To</label>
										<input
											type="number"
											className="price-filter"
											placeholder="1000"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-9">
							<div className="row">
								{plantData?.map((item, index) => {
									return (
										<AllPlantsCard
											item={item}
											index={index}
											colValue={"4"}
											links="/products-detailPage"
										/>
									);
								})}
							</div>
						</div>
					</div>
					<div className="pagination-container mt-5">
						<ReactPaginate
							previousLabel="<"
							nextLabel=">"
							breakLabel={"..."}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={handlePageClick}
							containerClassName={"pagination justify-content-center"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
						/>
						<p className="Total-Product">12 PRODUCTS 1 - 12</p>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Products;
