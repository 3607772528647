import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import "../../assets/css/BookingMember.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
	booking1,
	booking2,
	booking3,
	booking4,
	booking5,
} from "../../constant";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import { style } from "@mui/system";

function BookingMemberShip() {
	const data = [
		{
			id: 1,
			title: "Camping",
			img: booking1,
			link: "/camping",
		},
		{
			id: 2,
			title: "Garden Tour",
			img: booking2,
			link: "/garden-tour",
		},
		{
			id: 3,
			title: "Farmers Booth Space",
			img: booking3,
			link: "/framers-booth",
		},
		{
			id: 4,
			title: "Family Membership",
			img: booking4,
			link: "/family-membership",
		},
		{
			id: 5,
			title: "CSA Membership",
			img: booking5,
			link: "/csa-membership",
		},
	];
	const navigate = useNavigate();
	return (
		<>
			<Header />
			<MainBanner name={"Bookings & Memberships"} />
			<section className="bookingMember-page">
				<div className="container">
					<div>
						<h2 className="main-Title">Buy from Our Best Membership</h2>
						<p className="main-para">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.
						</p>
					</div>

					<div className="row">
						{data?.slice(0, 3)?.map((item, index) => {
							return (
								<div
									className="col-lg-4 col-sm-6"
									key={index}
									onClick={() => navigate(item.link)}
									style={{ cursor: "pointer" }}
								>
									<div className="member-card">
										<div>
											<img src={item?.img} alt="" className="image-fluid" />
										</div>
										<p className="title">{item?.title}</p>
									</div>
								</div>
							);
						})}

						<div className="col-lg-2"></div>
						{data?.map((item, index) => {
							return (
								<>
									{index > 2 ? (
										<div
											className="col-lg-4 col-sm-6"
											key={index}
											onClick={() => navigate(item.link)}
											style={{ cursor: "pointer" }}
										>
											<div className="member-card">
												<div>
													<img src={item?.img} alt="" className="image-fluid" />
												</div>
												<p className="title">{item?.title}</p>
											</div>
										</div>
									) : null}
								</>
							);
						})}
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			<ActionButton />
			<Footer />
		</>
	);
}

export default BookingMemberShip;
