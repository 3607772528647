import React, { useEffect } from "react";
import "../../assets/css/Thankyou.css";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Thankyou.css";
import MainBanner from "../Banner/MainBanner";

const Thankyou = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />

			<MainBanner name={"Thankyou"} />
			<section className="thankyou">
				<div className="container">
					<div className="happyShopping">
						<h3 className="font-30 fw-600 pb-4">Thank you for Shopping</h3>

						<div className="OrderDetails">
							<p>Order Id: #1045487879879879</p>
							<p>Amount: $11</p>
							<p>Payment Method: Cash</p>
							<p>Status: Pending</p>
						</div>

						<div className="button-group">
							<button className="btn setButtonThank">Continue Shopping</button>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default Thankyou;
