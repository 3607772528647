import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/Profile.css";
import SideBar from "../../Components/SideBar/SideBar";
import { Link } from "react-router-dom";
import TopBar from "../../Components/SideBar/TopBar";

function Profile() {
	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState("");
	const [uploadLoading, setUploadLoading] = useState(false);
	const [editState, setEditState] = useState(false);
	const [passLoading, setPassLoading] = useState(false);

	const [firstName, setFirstName] = useState("John");
	const [lastName, setLastName] = useState("Doe");
	const [phone, setPhone] = useState("0123432432");
	const [editLoading, setEditLoading] = useState(false);

	const [pass, setPass] = useState("*******");
	const [currentPassword, setCurrentPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [passwordChange, setPasswordChange] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleImageUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		setUploadLoading(true);
	};

	const EditProfile = (e) => {
		e.preventDefault();
	};

	const changePassword = (e) => {
		e.preventDefault();
	};

	const LogoutHandler = (e) => {};

	return (
		<>
			<Header />

			{/* <!-- Universal Banner Sec End Here --> */}
			<section className="profile">
				<div className="container">
					<div className="row profile-row">
						<SideBar
							uploadLoading={uploadLoading}
							fileupload={fileupload}
							handleImageUpload={handleImageUpload}
						/>
						<div className="col-lg-9">
							<div className="tab-content" id="v-pills-tabContent">
								<div
									className="tab-pane fade active show"
									id="pills-home"
									role="tabpanel"
									aria-labelledby="pills-home-tab"
								>
									<TopBar />
									<div className="row"></div>
									<div className="row info-profile">
										<div className="col-md-12 rightSide-profile">
											<div className="Accounts">
												<h5>Personal Information</h5>
												<div>
													{editState ? (
														<button
															className="btn btn-large logout-btn mr-2"
															onClick={(e) => EditProfile(e)}
															disabled={editLoading}
														>
															{editLoading ? "Loading..." : "SAVE CHANGES"}
															&nbsp;
														</button>
													) : (
														<button
															className="btn btn-large logout-btn mr-2"
															onClick={() => setEditState(true)}
														>
															EDIT PROFILE &nbsp;
														</button>
													)}
												</div>
											</div>

											{editState ? (
												<div className="col-lg-10">
													<div className="account-form-wrapper">
														<h3 className="form-heading">Account</h3>
														<form action="">
															<div className="form-group row">
																<div className="col-lg-6">
																	<label for="FirstName">First Name</label>
																	<input
																		type="text"
																		className="form-control"
																		value={firstName}
																		onChange={(e) =>
																			setFirstName(e.target.value)
																		}
																	/>
																</div>
																<div className="col-lg-6">
																	<label for="lastName">last Name</label>
																	<input
																		type="text"
																		className="form-control"
																		value={lastName}
																		onChange={(e) =>
																			setLastName(e.target.value)
																		}
																	/>
																</div>
															</div>
															<div className="form-group row">
																<div className="col-lg-6">
																	<label for="PhoneNumber">Phone Number</label>
																	<input
																		type="number"
																		className="form-control"
																		value={phone}
																		onChange={(e) => setPhone(e.target.value)}
																	/>
																</div>
															</div>
														</form>
													</div>
												</div>
											) : (
												<>
													<div className="row">
														<div className="col-lg-6">
															<div className="first-name input-box">
																<h4>First Name</h4>
																<h3>{"John"}</h3>
															</div>
														</div>

														<div className="col-lg-6">
															<div className="first-name input-box">
																<h4>Last Name</h4>
																<h3>{"Doe"}</h3>
															</div>
														</div>

														<div className="col-lg-6 phone">
															<div className="first-name input-box">
																<h4>Phone</h4>
																<h3>
																	<a href="tel:+1 (322) 422 654">
																		+{"01234567744"}
																	</a>
																</h3>
															</div>
														</div>

														<div className="col-lg-6 phone">
															<div className="first-name input-box">
																<h4>Email</h4>
																<h3>{"johndoe@mail.com"}</h3>
															</div>
														</div>
														<div className="col-lg-6 phone">
															<div className="first-name input-box">
																<h4>Password</h4>
																<h3>{"*******"}</h3>
															</div>
														</div>
													</div>

													<div className="row for-allign">
														<div className="col-md-4">
															<div className="password-btn">
																<button
																	onClick={(e) => setPasswordChange(true)}
																	// onClick={() => setEditState(true)}
																>
																	Change Password
																</button>
															</div>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
									{passwordChange ? (
										<div className="security-form-wrapper pt-5">
											<h3 className="form-heading">Change Password</h3>
											<form action="">
												<div className="form-group row">
													<div className="col-lg-6">
														<label for="oldpassword">Old Password</label>
														<input
															type="password"
															className="form-control"
															value={currentPassword}
															onChange={(e) =>
																setCurrentPassword(e.target.value)
															}
														/>
													</div>
													<div className="col-lg-6">
														<label for="newpassword">New Password</label>
														<input
															type="password"
															className="form-control"
															value={password}
															onChange={(e) => setPassword(e.target.value)}
														/>
													</div>
												</div>
												<div className="form-group row">
													<div className="col-lg-6">
														<label for="confirmpassword">
															Confirm Password
														</label>
														<input
															type="password"
															className="form-control"
															value={confirmPassword}
															onChange={(e) =>
																setConfirmPassword(e.target.value)
															}
														/>
													</div>
												</div>

												<button
													className="btn btn-submit"
													onClick={(e) => changePassword(e)}
													disabled={passLoading}
												>
													{passLoading ? "Loading.." : "SAVE PASSWORD "}
													&nbsp;{" "}
												</button>
											</form>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default Profile;
