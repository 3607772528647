import React, { useState } from "react";
import "../../assets/css/ActionBtn.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookF,
	faInstagram,
	faTwitter,
	faYoutube,
	faPinterestP,
} from "@fortawesome/free-brands-svg-icons";

// transform: translateY(-60px)
function ActionButton() {
	const [state, setState] = useState(false);

	const handleOpen = () => {
		setState(!state);
	};
	return (
		<div className="actionLink">
			<div className="button-group">
				<div className="box_setData">
					<div className="allIcons">
						<div className="contact-btn">
							<p
								className="followus"
								style={{
									color: "#fff",
									transform: "rotate(0deg)",
									fontSize: "15px",
								}}
							>
								Follow us
							</p>
							<FontAwesomeIcon
								icon={faPinterestP}
								style={{
									color: "#fff",
									transform: "rotate(90deg)",
									fontSize: "1.3rem",
								}}
							/>
							<FontAwesomeIcon
								icon={faYoutube}
								style={{
									color: "#fff",
									transform: "rotate(90deg)",
									fontSize: "1.3rem",
								}}
							/>
							<FontAwesomeIcon
								icon={faTwitter}
								style={{
									color: "#fff",
									transform: "rotate(90deg)",
									fontSize: "1.3rem",
								}}
							/>
							<FontAwesomeIcon
								icon={faInstagram}
								style={{
									color: "#fff",
									transform: "rotate(90deg)",
									fontSize: "1.3rem",
								}}
							/>
							<FontAwesomeIcon
								icon={faFacebookF}
								style={{
									color: "#fff",
									transform: "rotate(90deg)",
									fontSize: "1.3rem",
								}}
							/>
						</div>
					</div>
				</div>
				<div className="box_setData">
					<div
						className="contactDv"
						style={{ transform: state && "translateY(-60px)" }}
					>
						<div className="contact-btn" onClick={handleOpen}>
							<LocalPhoneIcon
								style={{
									color: "#fff",
									transform: "rotate(80deg)",
									fontSize: "2.5rem",
								}}
							/>
						</div>
						<div className="chatDv">
							<h3>Chat with Us</h3>
							<form>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										placeholder="Your Name"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="email"
										placeholder="Email"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										placeholder="Contact"
									/>
								</div>
								<div className="form-group">
									<textarea
										className="form-control"
										placeholder="Your Message"
									></textarea>
								</div>
								<button type="submit">Submit</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ActionButton;
