import React, { useEffect, useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/CSAMembership.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { arrow, checkbox2 } from "../../constant";
import Apple from "../../assets/img/booking/garder_town/apple.png";
import google from "../../assets/img/booking/garder_town/google.png";
// import paypal from "../../assets/img/booking/garder_town/paypal.png";
import mastercard from "../../assets/img/booking/garder_town/mastercard.png";
import visa from "../../assets/img/booking/garder_town/visacard.png";
import Cardpacked from "../../assets/img/booking/garder_town/Cardpacked.png";
import StripeForm from "../../Components/PaymentMethod/StripeForm";
import ActionButton from "../../Components/ActionBtn/ActionButton";
function CSAMEMBERship() {
	const [state, setState] = useState();
	const styling = {
		marginTop: "10px",
		width: "100%",
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	let selectBox = [
		{ id: 1, text: "Weekly", price: "$25.00" },
		{ id: 2, text: "Bi-Weekly", price: "$25.00" },
		{ id: 3, text: "Monthly", price: "$25.00" },
	];
	const HandleCheck = (id) => {
		console.log(id);
		setState(id);
		// selectBox.filter((item) =>
		// 	item.id !== id ? setState(false) : setState(true),
		// );
	};

	return (
		<>
			<Header />
			<MainBanner name={"CSA Membership"} />
			<section className="Membership-page">
				<div className="container">
					<div>
						<h2 className="main-Title">CSA Membership</h2>
						<p className="main-para">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.
						</p>
						<ActionButton />
						<div className="membership-card">
							<div className="row">
								<div className="col-lg-4">
									<div className="card cardStyle2">
										<img src={Cardpacked} alt="" />
										<h4>Product box 1</h4>
										<h6>This box includes</h6>
										<p className="main-para12">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua.
										</p>
										<button className="btn">BUY MEMBERSHIP TO GET</button>
									</div>
								</div>

								<div className="col-lg-4">
									<div className="card cardStyle">
										<img src={Cardpacked} alt="" />
										<h4>Product box 1</h4>
										<h6>This box includes</h6>
										<p className="main-para12">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua.
										</p>
										<button className="btn">BUY MEMBERSHIP TO GET</button>
									</div>
								</div>

								<div className="col-lg-4">
									<div className="card">
										<img src={Cardpacked} alt="" />
										<h4>Product box 1</h4>
										<h6>This box includes</h6>
										<p className="main-para12">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua.
										</p>
										<button className="btn">BUY MEMBERSHIP TO GET</button>
									</div>
								</div>
							</div>
						</div>

						<div className="formContainer">
							<h2 className="main-Title">Get CSA Membership</h2>
							<p className="main-para">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat.
							</p>
							<div className="row">
								<div className="col-lg-3"></div>
								<div className="col-lg-3">
									<div className="set-paymentDetail">
										<h6 className="margin-settin">Select From</h6>
										{selectBox.map((item, index) => {
											return (
												<div onClick={() => HandleCheck(item.id)}>
													<div
														className={` ${
															state == item.id
																? "form-check forms-set selectamoutdiv selectamoutdiv2"
																: "form-check forms-set selectamoutdiv selectamoutdiv23"
														} `}
													>
														{state == item.id ? (
															<img
																src={checkbox2}
																alt=""
																className="checkleft"
															/>
														) : (
															<input
																className="form-check-input"
																type="radio"
																value=""
																name="amount"
																id="amount1"
															/>
														)}
														&nbsp;
														<label htmlFor="amount1" className="labeltext">
															<div
																className={` ${
																	state == item.id
																		? "week_Amount "
																		: "week_Amount weekDollartxt11"
																} `}
															>
																<p className="weekDollartxt ween2">
																	{item.text}
																</p>
																<p className="weekDollar ween">{item.price}</p>
															</div>
														</label>
													</div>
												</div>
											);
										})}
									</div>
								</div>
								<div className="col-lg-4">
									<div className="set-maindiv">
										<h6>Payment Details</h6>
									</div>
									<StripeForm styling={styling} paynow="SUBMIT" />
								</div>

								<div className="col-lg-1"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default CSAMEMBERship;
