import React from "react";
import Header from "../../Components/Header/Header";
import "../../assets/css/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { arrow } from "../../constant";

function ForgotEmail() {
	const navigate = useNavigate();
	return (
		<>
			<Header />
			<section className="login">
				<div className="login-card">
					<div className="main-title-cont">
						<h2 className="main-title">Forget Password</h2>
					</div>
					<p className="forget-title">Write Yoru email to Recover Account</p>

					<div className="form-cont">
						<div className="mb-3">
							<label>Your Email</label>
							<input type="text" className="input-custom" />
						</div>

						<div className="btn-cont mt-2">
							<button className="btn loginBtn" onClick={() => navigate("/otp")}>
								SUBMIT <img src={arrow} alt="" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ForgotEmail;
