import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import StripeForm from "../../Components/PaymentMethod/StripeForm";

function Donate() {
	const styling = {
		marginTop: "10px",
		width: "20%",
		padding: "8px 16px",
		borderRadius: "20px",
	};
	return (
		<>
			<Header />
			<MainBanner name={"Campaign"} />
			<section className="contact-page">
				<div className="container">
					<div>
						<h2 className="main-Title">Donate Now</h2>
					</div>
					<div className="border-bottom22"></div>
					<div className="input-div mt-5">
						<div className="row amount_section">
							<h3>Choose Your Amount</h3>
							<div className="col-md-4">
								<label
									htmlFor="abcd"
									className="single_amount w-100 text-center"
								>
									<input type="radio" id="abcd" name="rad" hidden />
									<span>$ 50.00</span>
								</label>
							</div>
							<div className="col-md-4">
								<label
									htmlFor="abcd1"
									className="single_amount w-100 text-center"
								>
									<input type="radio" id="abcd1" name="rad" hidden />
									<span>$ 100.00</span>
								</label>
							</div>
							<div className="col-md-4">
								<label
									htmlFor="abcd2"
									className="single_amount w-100 text-center"
								>
									<input type="radio" id="abcd2" name="rad" hidden />
									<span>$ 150.00</span>
								</label>
							</div>
							<div className="col-md-12 mt-4">
								<label>Amount*</label>
								<input type="number" className="w-100" />
							</div>
						</div>
					</div>
					<div className="row mt-5 amount_section2">
						<h3 className="details_head">Details</h3>
						<div className="col-lg-6">
							<div className="input-div">
								<label>First Name*</label>
								<input type="text" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Last name*</label>
								<input type="text" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Email*</label>
								<input type="text" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Phone*</label>
								<input type="number" />
							</div>
						</div>
						<div className="col-lg-12">
							{/* <div className="btn-container mt-4 mb-5">
								<button className="btn book-btn2">Donate Now</button>
							</div> */}
							<div className="input-div mt-3 amount_section3">
								<h3 className="details_head2">Payment Details </h3>
								<StripeForm styling={styling} paynow="Donate Now" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Donate;
