import React from "react";
import { Link } from "react-router-dom";

function ProductCard(props) {
	const { index, item, colValue } = props;
	return (
		<>
			<div
				className={colValue == "4" ? "col-lg-4" : "col-lg-3 set_card"}
				key={index}
			>
				<Link to="/General-Hydroponic">
					<div className="p-card">
						<div className="img-cont">
							<img src={item?.img} alt="" className="image-fluid" />
						</div>
						<div className="content">
							<p className="title">Whisper Coneflower</p>
							{/* <p className="title2">$250.00</p> */}
						</div>
					</div>
				</Link>
			</div>
		</>
	);
}

export default ProductCard;
