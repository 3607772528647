import React, { useEffect, useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
	abt1,
	abt2,
	abt3,
	abt4,
	abts2,
	abts3,
	anm,
	arrow,
	arrow3,
	ht,
} from "../../constant";
import HomeContactForm from "../../Components/Forms/HomeContactForm";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Aboutus() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<MainBanner name={"About us"} />
			<section className="about-page">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="img-cont">
								<img src={abt1} alt="" />
							</div>
							<div className="img-cont">
								<img src={abt3} alt="" />
							</div>
						</div>
						<div className="col-lg-6">
							<h2 className="main-Title">Enjoy visiting our farm</h2>
							<p className="main-para">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
								in reprehenderit in voluptate velit esse cillum dolore eu fugiat
								nulla pariatur. Excepteur sint occaecat cupidatat non proident.
							</p>
							<p className="main-para">
								At augue eget arcu dictum varius duis at consectetur. Molestie a
								iaculis at erat pellentesque adipiscing commodo elit at. Varius
								quam quisque id diam vel quam elementum. Sed ullamcorper morbi
								tincidunt ornare. Egestas purus viverra accumsan in nisl nisi
								scelerisque eu ultrices.{" "}
							</p>

							<div className="btn-container mt-4 mb-5">
								<button className="btn BOOKNOW">
									BOOK NOW <img src={arrow3} alt="" />{" "}
								</button>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="img-cont">
								<img src={abt2} alt="" />
							</div>
							<div className="img-cont">
								<img src={abt4} alt="" />
							</div>
						</div>
					</div>
				</div>

				<section className="about-pageSec2">
					<div className="container">
						<div className="row">
							<div className="col-lg-5">
								<div className="img-cont h-100">
									<img src={abts2} alt="" />
								</div>
							</div>
							<div className="col-lg-7">
								<div className="about-us-text">
									<h2 className="shadow-title">lectus quam</h2>
									<h3 className="main-title">Odio ut enim blandit volutpat </h3>
									<p className="para">
										Tempor orci dapibus ultrices in iaculis nunc sed. Nisl
										rhoncus mattis rhoncus urna neque. Vestibulum mattis
										ullamcorper velit sed ullamcorper. Penatibus et magnis dis
										parturient montes nascetur ridiculus mus mauris. Nisl vel
										pretium lectus quam id leo. Odio ut enim blandit volutpat
										maecenas volutpat blandit. Nunc vel risus commodo viverra
										maecenas. Lacus luctus accumsan tortor posuere ac ut
										consequat. Molestie nunc non blandit massa enim nec dui.{" "}
									</p>
									<p className="para">
										{" "}
										Nisl vel pretium lectus quam id leo. Odio ut enim blandit
										volutpat maecenas volutpat blandit. Nunc vel risus commodo
										viverra maecenas. Lacus luctus accumsan tortor posuere ac ut
										consequat. Molestie nunc non blandit massa enim nec dui.{" "}
									</p>
									<p className="para mt-2">
										Viverra maecenas. Lacus luctus accumsan tortor posuere ac ut
										consequat. Molestie nunc non blandit massa enim nec dui.{" "}
									</p>
								</div>
							</div>

							<div className="col-lg-7 ">
								<div>
									<h2 className="shadow-title">lectus quam</h2>
									<h3 className="main-title">Odio ut enim blandit volutpat </h3>
									<p className="para">
										Tempor orci dapibus ultrices in iaculis nunc sed. Nisl
										rhoncus mattis rhoncus urna neque. Vestibulum mattis
										ullamcorper velit sed ullamcorper. Penatibus et magnis dis
										parturient montes nascetur ridiculus mus mauris. Nisl vel
										pretium lectus quam id leo. Odio ut enim blandit volutpat
										maecenas volutpat blandit. Nunc vel risus commodo viverra
										maecenas. Lacus luctus accumsan tortor posuere ac ut
										consequat. Molestie nunc non blandit massa enim nec dui.{" "}
									</p>
									<ul>
										<li className="para-points">
											<CheckCircleIcon style={{ width: "16px" }} /> &nbsp;&nbsp;
											Tempor orci dapibus ultrices in iaculis nunc sed.
										</li>
										<li className="para-points">
											<CheckCircleIcon style={{ width: "16px" }} /> &nbsp;
											&nbsp; Nisl rhoncus mattis rhoncus urna neque.
										</li>
										<li className="para-points">
											<CheckCircleIcon style={{ width: "16px" }} /> &nbsp;&nbsp;
											Vestibulum mattis ullamcorper velit sed ullamcorper.{" "}
										</li>
										<li className="para-points">
											<CheckCircleIcon style={{ width: "16px" }} />{" "}
											&nbsp;&nbsp;&nbsp;Penatibus et magnis dis parturient
											montes nascetur ridiculus
										</li>
									</ul>
								</div>
							</div>

							<div className="col-lg-5 mt-5">
								<div className="img-cont  h-100">
									<img src={abts3} alt="" />
								</div>
							</div>
						</div>
					</div>
				</section>
				<HomeContactForm />
			</section>

			<Footer />
		</>
	);
}

export default Aboutus;
