import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/Profile.css";
import SideBar from "../../Components/SideBar/SideBar";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../../Components/SideBar/TopBar";

function MyBooking() {
	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState("");
	const [uploadLoading, setUploadLoading] = useState(false);
	const [editState, setEditState] = useState(false);
	const [passLoading, setPassLoading] = useState(false);

	const [firstName, setFirstName] = useState("John");
	const [lastName, setLastName] = useState("Doe");
	const [phone, setPhone] = useState("0123432432");
	const [editLoading, setEditLoading] = useState(false);

	const [currentPassword, setCurrentPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [passwordChange, setPasswordChange] = useState(false);
	const [loading, setLoading] = useState(false);

	let navigate = useNavigate();

	const handleImageUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		setUploadLoading(true);
	};

	const myOrders = [
		{
			id: 1,
		},
		{
			id: 2,
		},
		{
			id: 3,
		},
		{
			id: 4,
		},
	];

	return (
		<>
			<Header />

			{/* <!-- Universal Banner Sec End Here --> */}
			<section className="profile">
				<div className="container">
					<div className="row profile-row">
						<SideBar
							uploadLoading={uploadLoading}
							fileupload={fileupload}
							handleImageUpload={handleImageUpload}
						/>
						<div className="col-lg-9">
							<div className="tab-content" id="v-pills-tabContent">
								<div
									className="tab-pane fade active show"
									id="pills-home"
									role="tabpanel"
									aria-labelledby="pills-home-tab"
								>
									<TopBar />

									<div className="row">
										<div className="col-lg-12 ">
											<h3 className="form-heading heading-top-cont">
												My Bookings
											</h3>
											<div className="order-table-wrapper table-responsive">
												<table>
													<tbody>
														<tr className="row1">
															<th className="order-no">Booking ID</th>
															<th className="order-date">Booking Type</th>
															<th className="order-status">Date & Time</th>
															<th className="order-status">Payment Status</th>
															<th className="order-pay">Total</th>
															<th className="details">Details</th>
														</tr>
														{myOrders?.map((item, index) => {
															return (
																<>
																	<tr className="row2" key={index}>
																		<td className="number">#124332</td>
																		<td className="date">Garden Tour</td>
																		<td className="date">
																			29 Dec 22 | 17:30 Am
																		</td>
																		<td className="paid confirm">
																			Paid
																			<i
																				className="fa fa-check-circle"
																				aria-hidden="true"
																			></i>
																		</td>
																		<td className="total-price">$7212</td>

																		<td className="order-details">
																			<a
																				className="order-details-btn"
																				onClick={() =>
																					navigate("/booking-details")
																				}
																			>
																				view Details
																			</a>
																		</td>
																	</tr>
																</>
															);
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default MyBooking;
