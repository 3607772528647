import React, { useEffect } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Policy.css";

function Cancelation() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<MainBanner name={"Cancelation Policy"} />

			<section className="policy-page">
				<div className="container">
					<h2 className="main-title">Our Cancelation policy </h2>

					<p className="para">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac
						auctor augue mauris augue neque gravida in fermentum et. Penatibus
						et magnis dis parturient. Convallis tellus id interdum velit laoreet
						id donec ultrices. Dolor purus non enim praesent elementum
						facilisis. Vulputate eu scelerisque felis imperdiet proin fermentum
						leo vel orci. Dolor morbi non arcu risus quis. Eget velit aliquet
						sagittis id consectetur purus ut. Quis viverra nibh cras pulvinar.
						Elit sed vulputate mi sit. Dui sapien eget mi proin sed. Consectetur
						a erat nam at lectus urna. Maecenas pharetra convallis posuere morbi
						leo urna molestie. Justo donec enim diam vulputate ut pharetra.
						Tempor nec feugiat nisl pretium fusce.
					</p>

					<p className="para">
						Ultricies mi eget mauris pharetra et ultrices neque. Sollicitudin
						tempor id eu nisl nunc mi ipsum faucibus vitae. Aliquet porttitor
						lacus luctus accumsan tortor posuere. Cursus mattis molestie a
						iaculis at erat pellentesque adipiscing commodo. In hac habitasse
						platea dictumst quisque. Orci eu lobortis elementum nibh tellus
						molestie nunc. Egestas erat imperdiet sed euismod nisi porta lorem
						mollis aliquam. Amet venenatis urna cursus eget nunc scelerisque
						viverra mauris in. Justo laoreet sit amet cursus sit amet dictum.
						Aliquam ultrices sagittis orci a. Nulla aliquet enim tortor at
						auctor urna nunc. Orci phasellus egestas tellus rutrum. Pellentesque
						dignissim enim sit amet. Orci porta non pulvinar neque laoreet.
						Molestie ac feugiat sed lectus vestibulum mattis ullamcorper.
					</p>

					<p className="para">
						Pretium lectus quam id leo. Turpis tincidunt id aliquet risus
						feugiat in ante. In vitae turpis massa sed elementum tempus egestas.
						Ligula ullamcorper malesuada proin libero. Turpis tincidunt id
						aliquet risus feugiat in ante metus. Sed turpis tincidunt id aliquet
						risus feugiat in ante metus. Sapien faucibus et molestie ac feugiat
						sed lectus. Donec enim diam vulputate ut pharetra. Id aliquet lectus
						proin nibh nisl condimentum id venenatis. Maecenas accumsan lacus
						vel facilisis volutpat est velit egestas. Amet nulla facilisi morbi
						tempus iaculis urna id volutpat lacus. Vel orci porta non pulvinar
						neque laoreet. Sit amet mattis vulputate enim nulla. Tortor vitae
						purus faucibus ornare suspendisse.
					</p>

					<p className="para">
						Nibh tortor id aliquet lectus proin nibh nisl condimentum. Nam
						libero justo laoreet sit amet cursus sit amet. Risus sed vulputate
						odio ut. Feugiat vivamus at augue eget. Nunc eget lorem dolor sed
						viverra. Nulla facilisi etiam dignissim diam quis. Arcu odio ut sem
						nulla pharetra diam sit. Venenatis lectus magna fringilla urna
						porttitor rhoncus dolor. Massa tempor nec feugiat nisl pretium fusce
						id. Lorem ipsum dolor sit amet consectetur adipiscing elit. Volutpat
						ac tincidunt vitae semper quis. Vulputate enim nulla aliquet
						porttitor lacus luctus accumsan. Purus sit amet volutpat consequat
						mauris nunc congue nisi. Luctus accumsan tortor posuere ac ut
						consequat semper viverra nam. Venenatis a condimentum vitae sapien
						pellentesque habitant morbi tristique. Aliquam etiam erat velit
						scelerisque. Nisl purus in mollis nunc sed id semper risus in. Netus
						et malesuada fames ac.
					</p>

					<p className="para">
						Aenean pharetra magna ac placerat vestibulum lectus mauris ultrices
						eros. Hendrerit dolor magna eget est lorem ipsum dolor. Purus
						faucibus ornare suspendisse sed nisi lacus sed viverra. Tempor
						commodo ullamcorper a lacus vestibulum sed arcu non odio. Tortor
						condimentum lacinia quis vel eros. Morbi tristique senectus et netus
						et malesuada fames ac turpis. Dictum at tempor commodo ullamcorper
						a. Condimentum mattis pellentesque id nibh tortor id aliquet. Sociis
						natoque penatibus et magnis dis parturient montes nascetur
						ridiculus. Amet tellus cras adipiscing enim. Viverra tellus in hac
						habitasse platea dictumst. Volutpat sed cras ornare arcu dui
						vivamus. Libero id faucibus nisl tincidunt eget nullam non. Odio ut
						sem nulla pharetra diam sit amet nisl. Habitant morbi tristique
						senectus et. Accumsan tortor posuere ac ut consequat. Feugiat nisl
						pretium fusce id. Arcu felis bibendum ut tristique.
					</p>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Cancelation;
