import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { plant1, plant2, plant3 } from "../../constant";
import "../../assets/css/AllPlants.css";
import AllPlantsCard from "./AllPlantsCard";
import ReactPaginate from "react-paginate";
import ActionButton from "../../Components/ActionBtn/ActionButton";

function AllPlants() {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(10);
	const [GrowthShow, setGrowthShow] = useState(false);

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	const plantData = [
		{
			id: 1,
			img: plant1,
		},
		{
			id: 2,
			img: plant2,
		},
		{
			id: 3,
			img: plant3,
		},
		{
			id: 4,
			img: plant2,
		},
		{
			id: 5,
			img: plant1,
		},
		{
			id: 6,
			img: plant3,
		},
		{
			id: 7,
			img: plant1,
		},
		{
			id: 8,
			img: plant2,
		},
		{
			id: 9,
			img: plant3,
		},
		{
			id: 10,
			img: plant1,
		},
		{
			id: 11,
			img: plant2,
		},
		{
			id: 12,
			img: plant3,
		},
	];

	const cateData = [
		{
			id: 1,
			tilte: "Lorem",
		},
		{
			id: 2,
			tilte: "Ipsum",
		},
		{
			id: 3,
			tilte: "Dolor",
		},
		{
			id: 4,
			tilte: "Sit",
		},
	];
	const FilterGrowth = [
		{
			id: 1,
			tilte: "2-8 year",
		},
		{
			id: 2,
			tilte: "8-12 year",
		},
		{
			id: 3,
			tilte: "12-16 year",
		},
		{
			id: 4,
			tilte: "16-20 year",
		},
	];

	const HandleOpenGrowth = () => {
		setGrowthShow(!GrowthShow);
	};
	return (
		<>
			<Header />
			<MainBanner name={"All Plants"} />
			<section className="allPlants-page">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="filter-side">
								<div className="title-cont">
									<h2 className="filter-title">Apply Filters</h2>
								</div>
								<div className="search-bar-cont">
									<input type="text" placeholder="SEARCH" className="search" />
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="18"
										height="18"
										viewBox="0 0 21.751 21.751"
									>
										<path
											id="Icon_material-search"
											data-name="Icon material-search"
											d="M20.045,18.18h-.982l-.348-.336a8.1,8.1,0,1,0-.871.871l.336.348v.982L24.4,26.251,26.251,24.4Zm-7.462,0a5.6,5.6,0,1,1,5.6-5.6A5.589,5.589,0,0,1,12.584,18.18Z"
											transform="translate(-4.5 -4.5)"
											fill="#fff"
											opacity="0.4"
										/>
									</svg>
								</div>
								<p className="maincate-head">Species</p>
								{cateData?.map((item, index) => {
									return (
										<div className="cate-box" key={index}>
											<p className="title">{item.tilte}</p>
											<input type="checkbox" value="Bike"></input>
										</div>
									);
								})}

								<p className="load-more">LOAD MORE</p>

								<p className="maincate-head">Color</p>
								{cateData?.map((item, index) => {
									return (
										<div className="cate-box" key={index}>
											<p className="title">{item.tilte}</p>
											<input type="checkbox" value="Bike"></input>
										</div>
									);
								})}
								<p className="load-more">LOAD MORE</p>

								<p className="maincate-head">Leaf Type</p>
								{cateData?.map((item, index) => {
									return (
										<div className="cate-box" key={index}>
											<p className="title">{item.tilte}</p>
											<input type="checkbox" value="Bike"></input>
										</div>
									);
								})}
								<p className="load-more">LOAD MORE</p>
								{/* <p className="maincate-head">Growth</p> */}
								<div className="d-flex justify-content-between">
									<p className="load-more">Growth</p>
								</div>
								{FilterGrowth?.map((item, index) => {
									return (
										<div className="cate-box" key={index}>
											<p className="title">{item.tilte}</p>
											<input type="checkbox" value="Bike"></input>
										</div>
									);
								})}
								<p className="load-more">LOAD MORE</p>

								<p className="maincate-head">Price</p>
								<div className="row">
									<div className="col-lg-6">
										<label className="price-text">From</label>
										<input
											type="number"
											className="price-filter"
											placeholder="1"
										/>
									</div>
									<div className="col-lg-6">
										<label className="price-text">To</label>
										<input
											type="number"
											className="price-filter"
											placeholder="1000"
										/>
									</div>
								</div>
								<div className="HardnessZone">
									<select name="" id="" class="form-control">
										<option selected>Hardiness Zone</option>
										<option value="">3 to 5</option>
										<option value="">5 to 10</option>
										<option value="">10 to 15</option>
									</select>
								</div>
							</div>
						</div>

						<div className="col-lg-9 mt-4">
							<ActionButton />

							<div className="row">
								{plantData?.map((item, index) => {
									return (
										<>
											<AllPlantsCard
												item={item}
												index={index}
												colValue={"4"}
												links="/product-details"
											/>
										</>
									);
								})}
							</div>
							<div className="pagination-container mt-5">
								<ReactPaginate
									previousLabel="<"
									nextLabel=">"
									breakLabel={"..."}
									pageCount={pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={3}
									onPageChange={handlePageClick}
									containerClassName={"pagination justify-content-center"}
									pageClassName={"page-item"}
									pageLinkClassName={"page-link"}
									previousClassName={"page-item"}
									previousLinkClassName={"page-link"}
									nextClassName={"page-item"}
									nextLinkClassName={"page-link"}
									breakClassName={"page-item"}
									breakLinkClassName={"page-link"}
									activeClassName={"active"}
								/>
								<p className="Total-Product">12 PRODUCTS 1 - 12</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default AllPlants;
