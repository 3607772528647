import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { ProdImg1 } from "../../constant";

function NutrientsProSlider() {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	const slider1 = useRef(null);
	const slider2 = useRef(null);

	useEffect(() => {
		setNav1(slider1.current);
		setNav2(slider2.current);
	}, []);

	const ParamData = [
		{
			id: 1,
			img: ProdImg1,
		},
		{
			id: 2,
			img: ProdImg1,
		},
		{
			id: 3,
			img: ProdImg1,
		},
	];

	return (
		<>
			<div className="product-slide">
				<Slider asNavFor={nav2} ref={slider1} arrows={false}>
					{ParamData?.map((item, index) => {
						console.log(item);
						return (
							<div className="product-images mainimage-container" key={index}>
								<figure>
									<img
										src={item?.img}
										alt=""
										className="image-fluid image-width"
									/>
								</figure>
							</div>
						);
					})}
				</Slider>

				<Slider
					asNavFor={nav1}
					ref={slider2}
					slidesToShow={3}
					swipeToSlide={true}
					focusOnSelect={true}
					arrows={false}
					className="slick-custom"
				>
					{ParamData?.map((item, index) => {
						console.log(item);
						return (
							<div className="inner-image-container" key={index}>
								<figure>
									<img
										src={item?.img}
										alt=""
										className="image-fluid image-width"
									/>
								</figure>
							</div>
						);
					})}
				</Slider>
			</div>
		</>
	);
}

export default NutrientsProSlider;
