import React, { useState } from "react";
import { useLocation } from "react-router-dom";
// import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
// import Heart from "react-heart";
import ProductImagesSlider from "../../Components/Slider/ProductImagesSlider";
import AllReviews from "../../Components/Reviews/AllReviews";
import ReviewBox from "../../Components/Reviews/ReviewBox";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { map, plant1, v1 } from "../../constant";
import ProductCard from "../../Components/Cards/ProductCard";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FrequentlyCard from "./FrequentlyCard";
import MainBanner from "../../Components/Banner/MainBanner";
import NutrientsProSlider from "../../Components/Slider/NutrientsProSlider";
import "../../assets/css/ProductDetails.css";
import { style } from "@mui/system";

function GeneralHydroponic() {
	const location = useLocation();
	console.log(location?.state?.data);
	const ParamData = location?.state?.data;
	console.log(ParamData);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [active, setActive] = useState(false);
	const [rating, setRating] = useState(0);

	const handleRating = (rate) => {
		if (rate == "20") {
			setRating(1);
		} else if (rate == "40") {
			setRating(2);
		} else if (rate == "60") {
			setRating(3);
		} else if (rate == "80") {
			setRating(4);
		} else {
			setRating(5);
		}
		// setRating(rate);
	};

	return (
		<>
			<Header />
			<MainBanner name={"General Hydroponics"} />
			<div className="product-details hypdross">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<NutrientsProSlider />
						</div>
						<div
							className="col-lg-6"
							style={{ zIndex: "999", position: "relative" }}
						>
							<div style={{ zIndex: "9999" }}>
								<div className="row">
									<div className="col-lg-6">
										<p className="maples">Nutrients and Additives</p>
										<h3 className="productName  productName2">
											General Hydroponics
										</h3>
									</div>
									<div className="col-lg-6">
										<div className="tags-right">
											<p>Unit Price : $50.50</p>
										</div>
									</div>
								</div>
								<p className="desc-title">Description</p>
								<p className="productDesc">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Duis aute
									irure dolor in reprehenderit in voluptate velit esse cillum
									dolore eu fugiat nulla pariatur. Excepteur sint occaecat
									cupidatat non proident.
								</p>

								{/* <hr style={{ color: "#ffff" }} /> */}
								<div className="row">
									<div className="col-md-12 col-sm-12 col-12 pack-wrap">
										<p className="desc-title">Choose Your Pack: </p>
										<div className="packValue">
											<p>
												01
												<br />
												<span>$5</span>
											</p>
										</div>
										<div className="packValue">
											<p>
												03
												<br />
												<span>$15</span>
											</p>
										</div>
										<div className="packValue">
											<p>
												09
												<br />
												<span>$45</span>
											</p>
										</div>
									</div>
								</div>

								<p className="desc-title title2">Donation </p>
								<div className="row main-borderAmount">
									<div className="col-lg-6 col-sm-12 col-12 quantity-wrap">
										<div className="text2">Amount to donate : </div>
										<div>
											<select name="size" id="size" className="sizeSelect">
												<option value="S">$15</option>
												<option value="M">SMALL</option>
												<option value="L">LARGE</option>
												<option value="XL">XL</option>
											</select>
										</div>
									</div>
									<div className="col-lg-6 col-sm-12 col-12 quantity-wrap">
										<div className="text2">Select Campaign : </div>
										<div>
											<select name="size" id="size" className="sizeSelect">
												<option value="S">Save Nature</option>
												<option value="M">SMALL</option>
												<option value="L">LARGE</option>
												<option value="XL">XL</option>
											</select>
										</div>
									</div>
								</div>

								<div className="mt-4 mb-4 cardButtonAdjust">
									<button type="button" className="btn sizeBtn setbuttonprod">
										Add To Cart &nbsp;
										<span>
											<ShoppingCartOutlinedIcon
												style={{ color: "#296253", fontSize: "18px" }}
												className="cartIcon"
											/>
										</span>
									</button>
									<button
										type="button"
										className="btn sizeBtn"
										onClick={() => setIsOpenModal(true)}
									>
										Add To Wishlist &nbsp;
										<span>
											<FavoriteBorderOutlinedIcon
												className="heartIcon"
												style={{ color: "#296253", fontSize: "18px" }}
											/>
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="bottom-container">
						<section className="ClientsReviewsSec">
							<div className="container">
								<div className="tab-content" id="myTabContent">
									<div
										className="tab-pane fade show active"
										id="reviews"
										role="tabpanel"
										aria-labelledby="reviews-tab"
									>
										<div className="row">
											<div className="col-lg-8">
												<div className="review-container">
													<div>
														<AllReviews />
													</div>
													<hr />
													<div>
														<ReviewBox
															handleRating={handleRating}
															rating={rating}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<ActionButton />

			<Footer />
		</>
	);
}

export default GeneralHydroponic;
