import React from "react";
import Header from "../../Components/Header/Header";
import "../../assets/css/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { arrow } from "../../constant";

function OtpPage() {
	const navigate = useNavigate();
	return (
		<>
			<Header />
			<section className="login">
				<div className="login-card">
					<div className="main-title-cont">
						<h2 className="main-title">OTP Verification</h2>
					</div>
					<p className="forget-title">Enter the OTP sent to your number</p>

					<div className="form-cont linkSetOtp">
						<div className="mb-3">
							<label>Your OTP</label>
							<input type="text" className="input-custom" />
						</div>
						<span>
							Dont receive the OTP? <Link to="">RESEND OTP</Link>
						</span>
						<div className="btn-cont mt-2">
							<button
								className="btn loginBtn"
								onClick={() => navigate("/new-password")}
							>
								Verify & Proceed <img src={arrow} alt="" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default OtpPage;
