import React, { useEffect, useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ActionButton from "../../Components/ActionBtn/ActionButton";

function Contactus() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<MainBanner name={"Contact us"} />
			<section className="contact-page">
				<div className="container">
					<div>
						<h2 className="main-Title">Let Us Answer All Your Questions </h2>
						<p className="main-para">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.{" "}
						</p>
					</div>
					<ActionButton />
					<div className="row">
						<div className="col-lg-6">
							<div className="input-div">
								<label>First name*</label>
								<input type="text" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Last name*</label>
								<input type="text" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Email*</label>
								<input type="text" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Phone*</label>
								<input type="number" />
							</div>
						</div>
						<div className="col-lg-12">
							<div className="input-div">
								<label>Message*</label>
								<textarea rows="4" cols="50"></textarea>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="input-div Contact-page">
								<button className="btn">Submit</button>
							</div>
						</div>
					</div>

					<div className="row mt-5">
						<div className="col-lg-4 comp-info">
							<div className="icon-cont">
								<FontAwesomeIcon icon={faEnvelope} className="icons-info" />
							</div>
							<div className="content">
								<h2 className="title">Email us</h2>
								<p className="detail">
									Email us for general queries, including marketing and
									partnership opportunities.
								</p>
								<p className="small-info">info@hohofarm.com</p>
							</div>
						</div>
						<div className="col-lg-4 comp-info">
							<div className="icon-cont">
								<i className="fa fa-phone icons-info" aria-hidden="true"></i>
							</div>
							<div className="content">
								<h2 className="title">Call Us</h2>
								<p className="detail">
									Call us to speak to a member of our team. We are always happy
									to help.
								</p>
								<p className="small-info">012-3456-7890</p>
							</div>
						</div>
						<div className="col-lg-4 comp-info">
							<div className="icon-cont">
								{/* <FontAwesomeIcon icon={faEnvelope} className="icons-info" /> */}
								<LocationOnIcon className="icons-info" />
							</div>
							<div className="content">
								<h2 className="title">Our Head Office</h2>
								<p className="detail">
									8182 Fulton Rd.
									<br />
									Amirican Plain, MA 02130
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Contactus;
