import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/PlantingMixes.css";
import { n1, n2, n3, n4 } from "../../constant";
import ProductCard from "../../Components/Cards/ProductCard";
import ReactPaginate from "react-paginate";

function NutrientsAdditives() {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(10);

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	const plantData = [
		{
			id: 1,
			img: n1,
		},
		{
			id: 2,
			img: n2,
		},
		{
			id: 3,
			img: n3,
		},
		{
			id: 4,
			img: n4,
		},
		{
			id: 5,
			img: n3,
		},
		{
			id: 6,
			img: n2,
		},
		{
			id: 7,
			img: n1,
		},
		{
			id: 8,
			img: n4,
		},
		{
			id: 10,
			img: n1,
		},
		{
			id: 11,
			img: n3,
		},
	];
	return (
		<>
			<Header />
			<MainBanner name={"Nutrients and Additives"} />
			<section className="plantingMixing-page">
				<div className="container">
					<div>
						<h2 className="main-Title">Browse From Our Best Planting Mixes </h2>
						<p className="main-para">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.{" "}
						</p>
					</div>
					<div className="row">
						{plantData?.map((item, index) => {
							return <ProductCard item={item} index={index} colValue={"3"} />;
						})}
					</div>
					<div className="pagination-container mt-5">
						<ReactPaginate
							previousLabel="<"
							nextLabel=">"
							breakLabel={"..."}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={handlePageClick}
							containerClassName={"pagination justify-content-center"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
						/>
						<p className="Total-Product">12 PRODUCTS 1 - 12</p>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default NutrientsAdditives;
