import React from "react";
import BookingCard from "../BookingCard/BookingCard";

function MyDonationDetails() {
	let details = {
		Paymentdate: "Payment Date :",
		Paymenttime: "Payment Time",
		PaymentStatus: "Payment Status",
		Campaign: "Campaign",
		Total: "Total",
	};

	let payDetails = {
		Paymentdate: "17:30 Am",
		Paymenttime: "29 Dec 22",
		PaymentStatus: "Paid",
		Campaign: "Save Nature",
		Total: "$50.00",
	};
	return (
		<BookingCard
			mainhead="My Donation"
			mainnumber="#1084598423154"
			smallhead="My Bookings"
			details={details}
			payDetails={payDetails}
		/>
	);
}

export default MyDonationDetails;
