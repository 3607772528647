import React from "react";
import BookingCard from "../BookingCard/BookingCard";

function MyBookings() {
	let details = {
		Paymentdate: "Booking Date :",
		Paymenttime: "Booking Time :",
		PaymentStatus: "Payment Status :",
		Total: "Total :",
	};

	let payDetails = {
		Paymentdate: "17:30 Am",
		Paymenttime: "29 Dec 22",
		PaymentStatus: "Paid",
		Total: "$50.00",
	};
	return (
		<BookingCard
			mainhead="My Booking"
			mainnumber="#1084598423154"
			smallhead="Order Detail"
			details={details}
			payDetails={payDetails}
		/>
	);
}

export default MyBookings;
