import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/OrderViewDetails.css";

import { plants } from "../../constant";
import ProductCard from "../../Components/Cards/ProductCard";
import ReactPaginate from "react-paginate";

function OrderViewDetails() {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(10);

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	return (
		<>
			<Header />
			<section className="plantingMixing-page">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="detail_amount_total">
								<div className="main_heading">
									<h2 className="main_title">Orders Details</h2>
									<h4 className="main_number">#1084598423154</h4>
								</div>
								<div className="card_details">
									<div className="details_head2">Product Detail</div>
									<div className="allDetailCard">
										<div className="all_booking_details mt-4">
											<div className="Coneflower">
												<img src={plants} alt="" />
												<div className="quantityset">
													<h4>Whisper Coneflower</h4>
													<p>Quantity: 2</p>
												</div>
											</div>
											<div>
												<p className="pricePlant">$158.07</p>
											</div>
										</div>

										<div className="all_booking_details mt-4">
											<div className="Coneflower">
												<img src={plants} alt="" />
												<div className="quantityset">
													<h4>Whisper Coneflower</h4>
													<p>Quantity: 2</p>
												</div>
											</div>
											<div>
												<p className="pricePlant">$158.07</p>
											</div>
										</div>
										<div className="all_booking_details mt-4">
											<div className="Coneflower">
												<img src={plants} alt="" />
												<div className="quantityset">
													<h4>Whisper Coneflower</h4>
													<p>Quantity: 2</p>
												</div>
											</div>
											<div>
												<p className="pricePlant">$158.07</p>
											</div>
										</div>
									</div>
									<div className="details_head2 head3">Order Detail</div>
									<div className="pricingDetail">
										<div className="row detailamount">
											<div className="col-lg-8 col-sm-8 Datepara col-6 floatright">
												<p>Date:</p>
												<p>Subtotal:</p>
												<p>Tax fee:</p>
												<p>Shipping fee:</p>
												<p>Coupon Discount:</p>
											</div>
											<div className="col-lg-4 col-sm-4 Datenumber col-6 floatleft">
												<p>#10005165551651</p>
												<p>$785.00</p>
												<p>$80.00</p>
												<p>$5.00</p>
												<p>$50.00</p>
											</div>
										</div>
									</div>
									<div className="details_head2 head3">Shipment</div>.
									<div className="row detailamount">
										<div className="col-md-8 Datepara col-6">
											<p>Estmated Arrival:</p>
										</div>
										<div className="col-md-4 Datenumber col-6">
											<p>24 November</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default OrderViewDetails;
