import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/AddressDetails.css";
import SideBar from "../../Components/SideBar/SideBar";
import { Link } from "react-router-dom";
import TopBar from "../../Components/SideBar/TopBar";

function MyAddress() {
	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState("");
	const [uploadLoading, setUploadLoading] = useState(false);
	const [editState, setEditState] = useState(false);
	const [passLoading, setPassLoading] = useState(false);
	const [editLoading, setEditLoading] = useState(false);
	const [firstName, setFirstName] = useState("John");
	const [lastName, setLastName] = useState("Doe");
	const [phone, setPhone] = useState("0123432432");

	const [loading, setLoading] = useState(false);

	const handleImageUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		setUploadLoading(true);
	};

	const EditProfile = (e) => {
		e.preventDefault();
		setEditState(!editState);
	};

	return (
		<>
			<Header />

			{/* <!-- Universal Banner Sec End Here --> */}
			<section className="profile myaddress_page">
				<div className="container">
					<div className="row profile-row set-row">
						<SideBar
							uploadLoading={uploadLoading}
							fileupload={fileupload}
							handleImageUpload={handleImageUpload}
						/>
						<div className="col-lg-9">
							<div className="tab-content" id="v-pills-tabContent">
								<div
									className="tab-pane fade active show"
									id="pills-home"
									role="tabpanel"
									aria-labelledby="pills-home-tab"
								>
									<TopBar />

									{!editState ? (
										<div className="row">
											<div className="col-lg-12 ">
												<div className="container-fluid">
													<div className="saved-address-heading">
														<div>
															<h3 className="form-heading heading-top-cont">
																Addresses
															</h3>
														</div>
														{/* <div>
														<Link to="#">Edit</Link>
													</div> */}
													</div>
													<form>
														<div className="adddress-form">
															<div className="namediv ">
																<div className="row">
																	<div className="col-lg-4">
																		<div className="firstname">
																			<label htmlFor="address">Address</label>
																			<input
																				type="text"
																				id="address"
																				placeholder="+1-234-567-890"
																			/>
																		</div>
																	</div>
																	<div className="col-lg-4">
																		<div className="lastname">
																			<label htmlFor="suite">
																				Suite,Apartment,etc
																			</label>
																			<input
																				type="text"
																				id="suite"
																				placeholder="Everest@gmail.com"
																			/>
																		</div>
																	</div>
																	<div className="col-lg-4">
																		{/* <div className="editbutton-address">
																		<Link to="#">Edit</Link>
																	</div> */}
																	</div>
																</div>
															</div>
															<div className="namediv">
																<div className="row">
																	<div className="col-lg-2">
																		<div className="firstname">
																			<label htmlFor="city">City</label>
																			<input
																				type="text"
																				id="city"
																				placeholder="New York"
																			/>
																		</div>
																	</div>
																	<div className="col-lg-2">
																		<div className="lastname">
																			<label htmlFor="state">State</label>
																			<input
																				type="text"
																				id="state"
																				placeholder="Arizona"
																			/>
																		</div>
																	</div>
																	<div className="col-lg-2">
																		<div className="lastname">
																			<label htmlFor="country">Country</label>
																			<input
																				type="text"
																				id="country"
																				placeholder="USA"
																			/>
																		</div>
																	</div>
																	<div className="col-lg-2">
																		<div className="lastname">
																			<label htmlFor="zip">Zip Code</label>
																			<input
																				type="text"
																				id="zip"
																				placeholder="98465"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</form>
													<div class="save-address headbtn address23">
														<div class="heading">
															<h3 className="form-heading heading-top-cont">
																Saved Addresses
															</h3>
														</div>
														{/* Saved Address  */}
														<div className="row">
															<div className="col-lg-8">
																<label class="container">
																	<input
																		type="radio"
																		checked="checked"
																		name="radio"
																	/>
																	<span class="checkmark">
																		<div class="select-address">
																			<div class="address-detail">
																				<div class="actions">
																					<a href="#">
																						<i
																							class="fa fa-pencil-square-o"
																							aria-hidden="true"
																							onClick={(e) => EditProfile(e)}
																						></i>
																					</a>
																					<a href="#">
																						<i
																							class="fa fa-trash"
																							aria-hidden="true"
																						></i>
																					</a>
																				</div>
																				<h5>
																					<span class="property">Country:</span>
																					<div class="spacer"></div>
																					<span class="value">
																						United States
																					</span>
																				</h5>
																				<h5>
																					<span class="property">Address:</span>
																					<div class="spacer"></div>
																					<span class="value">
																						10515 Fox Ave Fairdale,
																						Kentucky(KY), 40118
																					</span>
																				</h5>
																				<h5>
																					<span class="property">Phone:</span>
																					<div class="spacer"></div>
																					<span class="value">
																						+44-123-456-789
																					</span>
																				</h5>
																				<h5>
																					<span class="property">State:</span>
																					<div class="spacer"></div>
																					<span class="value">Texas</span>
																				</h5>
																				<div class="deafult">
																					<h5>Use as default</h5>
																					<span class="dot"></span>
																				</div>
																			</div>
																		</div>
																	</span>
																</label>
																<label class="container">
																	<input type="radio" name="radio" />
																	<span class="checkmark">
																		<div class="select-address">
																			<div class="address-detail">
																				<div class="actions">
																					<a href="#">
																						<i
																							class="fa fa-pencil-square-o"
																							aria-hidden="true"
																							onClick={(e) => EditProfile(e)}
																						></i>
																					</a>
																					<a href="#">
																						<i
																							class="fa fa-trash"
																							aria-hidden="true"
																						></i>
																					</a>
																				</div>
																				<h5>
																					<span class="property">Country:</span>
																					<div class="spacer"></div>
																					<span class="value">
																						United States
																					</span>
																				</h5>
																				<h5>
																					<span class="property">Address:</span>
																					<div class="spacer"></div>
																					<span class="value">
																						10515 Fox Ave Fairdale,
																						Kentucky(KY), 40118
																					</span>
																				</h5>
																				<h5>
																					<span class="property">Phone:</span>
																					<div class="spacer"></div>
																					<span class="value">
																						+44-123-456-789
																					</span>
																				</h5>
																				<h5>
																					<span class="property">State:</span>
																					<div class="spacer"></div>
																					<span class="value">Texas</span>
																				</h5>
																				<div class="deafult">
																					<h5>Use as default</h5>
																					<span class="dot"></span>
																				</div>
																			</div>
																		</div>
																	</span>
																</label>
															</div>
															<div className="col-lg-4"></div>
														</div>
														{/* Saved Address */}
													</div>
												</div>
											</div>
										</div>
									) : (
										<div className="col-lg-12">
											<div className="account-form-wrapper">
												<div className="Accounts2">
													<h3 className="form-heading">Account</h3>
													<div>
														<button
															className="btn logout-btn2 mr-2"
															onClick={(e) => EditProfile(e)}
															disabled={editLoading}
														>
															SAVE CHANGES &nbsp;
														</button>
													</div>
												</div>
												<form action="">
													<div className="form-group row">
														<div className="col-lg-6">
															<label for="FirstName">Address</label>
															<input
																type="text"
																className="form-control input_data"
																value="+1-234-567-980"
																onChange={(e) => setFirstName(e.target.value)}
															/>
														</div>
														<div className="col-lg-6">
															<label for="lastName">Suite,Apartment,etc</label>
															<input
																type="text"
																className="form-control input_data"
																value="Everest@gmail.com"
																onChange={(e) => setLastName(e.target.value)}
															/>
														</div>
													</div>
													<div className="form-group row">
														<div className="col-lg-6">
															<label for="PhoneNumber">City</label>
															<input
																type="text"
																className="form-control input_data"
																value="New York"
																onChange={(e) => setPhone(e.target.value)}
															/>
														</div>
														<div className="col-lg-6">
															<label for="PhoneNumber">State</label>
															<input
																type="text"
																className="form-control input_data"
																value="Arizona"
																onChange={(e) => setPhone(e.target.value)}
															/>
														</div>
													</div>
													<div className="form-group row">
														<div className="col-lg-6">
															<label for="PhoneNumber">Country</label>
															<input
																type="text"
																className="form-control input_data"
																value="USA"
																onChange={(e) => setPhone(e.target.value)}
															/>
														</div>
														<div className="col-lg-6">
															<label for="PhoneNumber">Zip Code</label>
															<input
																type="number"
																className="form-control input_data"
																value="98465"
																onChange={(e) => setPhone(e.target.value)}
															/>
														</div>
													</div>
												</form>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default MyAddress;
