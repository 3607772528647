import React from "react";
import Header from "../../Components/Header/Header";
import "../../assets/css/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { arrow } from "../../constant";

function NewPassword() {
	const navigate = useNavigate();
	return (
		<>
			<Header />
			<section className="login">
				<div className="login-card">
					<div className="main-title-cont">
						<h2 className="main-title password_tilte">Create new password</h2>
					</div>
					<p className="forget-title password-title2">
						Your new password must be different from previous used passwords.{" "}
					</p>

					<div className="form-cont linkSetOtp">
						<div className="change_password_first">
							<div className="mb-3">
								<label>Password</label>
								<input type="text" className="input-custom" />
							</div>
							<span className="password-alert">
								Must be at least 8 characters.
							</span>
						</div>
						<div className="change_password_first ">
							<div className="mb-3">
								<label>Confirm Password</label>
								<input type="text" className="input-custom" />
							</div>
							<span className="password-alert">Both passwords must match.</span>
						</div>

						<div className="btn-cont mt-5">
							<button className="btn loginBtn" onClick={() => navigate("/")}>
								Reset Password <img src={arrow} alt="" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default NewPassword;
