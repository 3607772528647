import logo from "./logo.svg";
import "./assets/css/Style.css";
import Header from "./Components/Header/Header";
import PublicRoutes from "./routes/PublicRoutes";
import "animate.css";

function App() {
	return (
		<>
			<PublicRoutes />
		</>
	);
}

export default App;
