import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/familyMembershipDetail.css";
import SideBar from "../../Components/SideBar/SideBar";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../../Components/SideBar/TopBar";
import { arrow, ProBox } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Signup from "./../Auth/Signup";

function FamilyMembershipDetail() {
	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState("");
	const [uploadLoading, setUploadLoading] = useState(false);
	const [editState, setEditState] = useState(false);
	const [passLoading, setPassLoading] = useState(false);

	const [firstName, setFirstName] = useState("John");
	const [lastName, setLastName] = useState("Doe");
	const [phone, setPhone] = useState("0123432432");
	const [editLoading, setEditLoading] = useState(false);

	const [currentPassword, setCurrentPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [passwordChange, setPasswordChange] = useState(false);
	const [loading, setLoading] = useState(false);
	const [val, setVal] = useState({
		name: "John",
		email: "johnjack@check.com",
		contact: "9898989898",
		dob: "26/9/2121",
		age: "23",
		gender: "Male",
		address: "ajadshuahew",
	});

	const navigate = useNavigate();

	const handleImageUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		setUploadLoading(true);
	};

	const EditProfile = (e) => {
		e.preventDefault();
	};

	const changePassword = (e) => {
		e.preventDefault();
	};

	return (
		<>
			<Header />

			{/* <!-- Universal Banner Sec End Here --> */}
			<section className="profile">
				<div className="container">
					<div className="row profile-row">
						<SideBar
							uploadLoading={uploadLoading}
							fileupload={fileupload}
							handleImageUpload={handleImageUpload}
						/>
						<div className="col-lg-9">
							<div className="tab-content" id="v-pills-tabContent">
								<div
									className="tab-pane fade active show"
									id="pills-home"
									role="tabpanel"
									aria-labelledby="pills-home-tab"
								>
									<TopBar />
									<div className="row"></div>
									<div className="row info-profile">
										<div className="col-md-12 rightSide-profile">
											<div className="row mb-4">
												<div className="col-lg-6">
													<div className="Accounts">
														<h5>My MemberShip</h5>
													</div>
													<p className="familyMember-Title">
														Family Membership
													</p>
													<p className="expiryBtn">
														Purchase date: 29 dec 2022 | Expiry date: 29 Jan
														2023
													</p>{" "}
													<p className="expiryBtn">
														Renewal date : 29 Jan 2023
													</p>
												</div>
												<div className="col-lg-6">
													<div className="familyMember-Title ">
														<button className="btn cancel-memberBtn3">
															schedule Garden Tour &nbsp;&nbsp;
															<img src={arrow} alt="" className="image-fluid" />
														</button>

														<button className="btn cancel-memberBtn cancel-memberBtn5">
															Cancel Membership &nbsp;&nbsp;
															<img src={arrow} alt="" className="image-fluid" />
														</button>
													</div>
												</div>
											</div>
											<hr />
											<div className="row csa_Membership">
												<div className="formContainer family-member_ship">
													<div className="family_mem_booking">
														<p className="sub-title">Members Details</p>
													</div>
													<div className="row">
														{/* <p className="sub-title">Members1</p> */}
														<div className="family_mem_booking">
															<p className="sub-title">Members1</p>
															<button
																data-toggle="modal"
																data-target="#exampleModalLong"
															>
																Edit
															</button>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Full Name</p>
															<input
																type="text"
																placeholder="Full Name"
																disabled
																value={val.name}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Email</p>
															<input
																type="email"
																placeholder="Email"
																disabled
																value={val.email}
															/>
														</div>

														<div className="col-lg-6">
															<p className="sub-title-sm">Contact Number</p>
															<input
																type="number"
																placeholder="Number"
																disabled
																value={val.contact}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Date Of birth</p>
															<input
																type="text"
																placeholder="DOB"
																disabled
																value={val.dob}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Age</p>
															<input
																type="number"
																placeholder="Age"
																disabled
																value={val.age}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Gender</p>
															<input
																type="text"
																disabled
																placeholder="Gender"
																value={val.gender}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Address</p>
															<input
																type="text"
																placeholder="Enter a Address"
																disabled
																value={val.address}
															/>
														</div>
													</div>
													<div className="row mt-5">
														<div className="col-lg-12">
															{/* <p className="sub-title">Members2</p> */}
															<div className="family_mem_booking">
																<p className="sub-title">Members2</p>
																<button
																	data-toggle="modal"
																	data-target="#exampleModalLong"
																>
																	Edit
																</button>
															</div>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Full Name</p>
															<input
																type="text"
																placeholder="Full Name"
																disabled
																value={val.name}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Email</p>
															<input
																type="email"
																placeholder="Email"
																disabled
																value={val.email}
															/>
														</div>

														<div className="col-lg-6">
															<p className="sub-title-sm">Contact Number</p>
															<input
																type="number"
																placeholder="Number"
																disabled
																value={val.contact}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Date Of birth</p>
															<input
																type="number"
																placeholder="DOB"
																disabled
																value={val.dob}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Age</p>
															<input
																type="number"
																placeholder="Age"
																disabled
																value={val.age}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Gender</p>
															<input
																type="text"
																disabled
																placeholder="Gender"
																value={val.gender}
															/>
														</div>
														<div className="col-lg-6">
															<p className="sub-title-sm">Address</p>
															<input
																type="text"
																placeholder="Enter a Address"
																disabled
																value={val.address}
															/>
														</div>
													</div>
												</div>
												<div
													class="modal fade"
													id="exampleModalLong"
													tabindex="-1"
													role="dialog"
													aria-labelledby="exampleModalLongTitle"
													aria-hidden="true"
												>
													<div class="modal-dialog" role="document">
														<div
															class="modal-content"
															style={{ background: "#f1efde" }}
														>
															<div class="modal-header">
																<h5
																	id="exampleModalLongTitle"
																	style={{
																		color: "#286152",
																	}}
																>
																	Member Details
																</h5>
																<button
																	type="button"
																	class="close"
																	data-dismiss="modal"
																	aria-label="Close"
																>
																	<span
																		aria-hidden="true"
																		style={{
																			color: "#286152",
																		}}
																	>
																		&times;
																	</span>
																</button>
															</div>
															<div class="modal-body">
																<div className="formContainer family-member_ship">
																	<div className="row ">
																		<div className="col-lg-6">
																			<p className="sub-title-sm">Full Name</p>
																			<input
																				style={{
																					outline: "none",
																				}}
																				type="text"
																				placeholder="Full Name"
																				value={val.name}
																			/>
																		</div>
																		<div className="col-lg-6">
																			<p className="sub-title-sm">Email</p>
																			<input
																				style={{
																					outline: "none",
																				}}
																				type="email"
																				placeholder="Email"
																				value={val.email}
																			/>
																		</div>

																		<div className="col-lg-6">
																			<p className="sub-title-sm">
																				Contact Number
																			</p>
																			<input
																				style={{
																					outline: "none",
																				}}
																				type="number"
																				placeholder="Number"
																				value={val.contact}
																			/>
																		</div>
																		<div className="col-lg-6">
																			<p className="sub-title-sm">
																				Date Of birth
																			</p>
																			<input
																				style={{
																					outline: "none",
																				}}
																				type="text"
																				placeholder="DOB"
																				value={val.dob}
																			/>
																		</div>
																		<div className="col-lg-6">
																			<p className="sub-title-sm">Age</p>
																			<input
																				style={{
																					outline: "none",
																				}}
																				type="number"
																				placeholder="Age"
																				value={val.age}
																			/>
																		</div>
																		<div className="col-lg-6">
																			<p className="sub-title-sm">Gender</p>
																			<input
																				style={{
																					outline: "none",
																				}}
																				type="text"
																				placeholder="Gender"
																				value={val.gender}
																			/>
																		</div>
																		<div className="col-lg-6">
																			<p className="sub-title-sm">Address</p>
																			<input
																				style={{
																					outline: "none",
																				}}
																				type="text"
																				placeholder="Enter a Address"
																				value={val.address}
																			/>
																		</div>
																	</div>
																</div>
															</div>
															<div class="modal-footer">
																<button
																	type="button"
																	class="btn btn-secondary"
																	data-dismiss="modal"
																	style={{
																		color: "#fff",
																		backgroundColor: "#bc0000",
																		border: "none",
																		outline: "none",
																	}}
																>
																	Close
																</button>
																<button
																	type="button"
																	class="btn"
																	style={{
																		color: "#fff",
																		backgroundColor: "#286152",
																		border: "none",
																		outline: "none",
																	}}
																>
																	Save changes
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default FamilyMembershipDetail;
