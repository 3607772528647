import React from "react";
import Header from "../../Components/Header/Header";
import "../../assets/css/Login.css";
import { Link } from "react-router-dom";
import { arrow } from "../../constant";

function Login() {
	return (
		<>
			<Header />
			<section className="login">
				<div className="login-card">
					<div className="main-title-cont">
						<hr className="hr-line" />
						<h2 className="main-title">Sign In</h2>
						<hr className="hr-line" />
					</div>
					<p className="inner-title">
						Welcome to back <span>Hoho Farms</span>
					</p>
					<div className="regs-cont">
						<p className="regs-text">Not a Member ?</p>
						<p className="regs-text">
							<Link to="/signup">Sign Up</Link>
						</p>
					</div>
					<div className="form-cont">
						<div className="mb-3">
							<label>Email</label>
							<input type="text" className="input-custom" />
						</div>
						<div>
							<label>Password</label>
							<input type="text" className="input-custom" />
						</div>
						<div className="link-box">
							<div className="rem-cont">
								<input
									type="checkbox"
									id="vehicle1"
									name="vehicle1"
									value="Bike"
									className="tick"
								/>
								<p className="rem-text">Remember Me</p>
							</div>
							<div>
								<Link to="/forget-email">Forgot Password ?</Link>
							</div>
						</div>
						<div className="btn-cont mt-2">
							<button className="btn loginBtn">
								SIGN IN <img src={arrow} alt="" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Login;
