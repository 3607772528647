import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Blog.css";
import MainBanner from "../../Components/Banner/MainBanner";
import { arrow, blog1 } from "../../constant";
import { useNavigate } from "react-router-dom";

function Blogs() {
	const blog = [
		{
			id: 1,
		},
		{
			id: 2,
		},
		{
			id: 3,
		},
		{
			id: 4,
		},
		{
			id: 5,
		},
		{
			id: 6,
		},
	];

	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<MainBanner name={"Blogs"} />
			<section className="blogs-page">
				<div className="container">
					<div>
						<h2 className="main-title">Read Our Most recent Blogs</h2>
						<p className="para">
							{" "}
							Nisl vel pretium lectus quam id leo. Odio ut enim blandit volutpat
							maecenas volutpat blandit. Nunc vel risus commodo viverra
							maecenas. Lacus luctus accumsan tortor posuere ac ut consequat.{" "}
						</p>
					</div>

					<div className="row">
						{blog?.map((item, index) => {
							return (
								<div className="col-lg-6" key={index}>
									<div
										className="tour_card"
										style={{ cursor: "pointer" }}
										onClick={() => navigate("/blog-details")}
									>
										<div className="img-cont">
											<img src={blog1} alt="" className="image-fluid" />
										</div>
										<div>
											<p className="tour_tag">Tourism </p>
											<p className="card_title">
												The Reason Why Everyone Love Farm
											</p>
											<p className="card_para">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Ut enim ad minim veniam, quis nostrud
												exercitation ullamco laboris nisi ut aliquip ex ea
												commodo consequat.
											</p>
										</div>
										<div>
											<button className="btn blog_more">
												Read More{" "}
												<img src={arrow} className="image-fluid" alt="" />
											</button>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Blogs;
