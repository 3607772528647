import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
// components
import Image from "../../assets/img/404/illustration_404.svg";
import "../../assets/css/Notfound.css";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
	maxWidth: 480,
	margin: "auto",
	minHeight: "calc(100vh - 200px)",
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	padding: theme.spacing(12, 0),
}));

const ButtonStyle = styled(Button)(({ theme }) => ({
	margin: "0",
	backgroundColor: "#296253",
	color: "#fff",
	padding: " 8px 28px",
	fontWeight: 500,
	borderRadius: "22px",
	border: "unset",
	// fontSize: "18px",
	textTransform: "uppercase",
	"&:hover": {
		backgroundColor: "#fff",
		color: "#000",
		border: "unset",
	},
}));

// ----------------------------------------------------------------------

export default function NotFound() {
	return (
		<>
			<Container>
				<ContentStyle
					sx={{
						textAlign: "center",
						alignItems: "center",
					}}
				>
					<Typography variant="h3" paragraph>
						Sorry, page not found!
					</Typography>

					<Typography sx={{ color: "text.secondary" }}>
						Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
						mistyped the URL? Be sure to check your spelling.
					</Typography>

					<Box
						component="img"
						src={Image}
						sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
					/>

					<ButtonStyle
						to="/"
						size="large"
						className="btn AddBtn"
						variant="contained"
						component={RouterLink}
					>
						Go to Home
					</ButtonStyle>
				</ContentStyle>
			</Container>
		</>
	);
}
