import React, { useEffect, useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Camping.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { arrow, dollar } from "../../constant";
import Apple from "../../assets/img/booking/garder_town/apple.png";
import google from "../../assets/img/booking/garder_town/google.png";
// import paypal from "../../assets/img/booking/garder_town/paypal.png";
import mastercard from "../../assets/img/booking/garder_town/mastercard.png";
import visa from "../../assets/img/booking/garder_town/visacard.png";
import StripeForm from "../../Components/PaymentMethod/StripeForm";
import ActionButton from "../../Components/ActionBtn/ActionButton";
function Camping() {
	const styling = {
		marginTop: "10px",
		width: "30%",
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<MainBanner name={"Camping"} />
			<section className="Camping-page">
				<div className="container">
					<div>
						<div className="Book_range_center">
							<h2 className="main-Title">Book 1 Night Camping </h2>
							<h3 className="priceRate">
								<img src={dollar} alt="" /> 250.00
							</h3>
						</div>
						<p className="main-para">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.
						</p>
						<ActionButton />
						<div className="formContainer">
							<p className="sub-title">Booking Details</p>
							<div className="row">
								<div className="col-lg-6">
									<p className="sub-title-sm">Select the Date</p>
									<input type="date" />
								</div>
								<div className="col-lg-6">
									<p className="sub-title-sm">Select Check-in Time</p>
									<input type="time" />
								</div>
								<div className="col-lg-6 addCampingRadio">
									<div className="row">
										{/* <div className="col-md-2 centerRadioBtn"></div> */}
										<div className="col-md-12">
											<p className="sub-title-sm with_check">
												<input type="checkbox" />
												<span>
													Add Garden tour to the package. (extra charges
													included)
												</span>
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<p className="sub-title-sm">
										Select Camping location on farm
									</p>
									<select>
										<option>Select</option>
										<option>Garder Town</option>
										<option>Garder Town</option>
									</select>
								</div>
							</div>

							<p className="sub-title">Personal Information </p>
							<div className="camping_btn">
								<button className="btn">
									SIGN IN
									<img src={arrow} className="image-fluid pl-2" />
								</button>
								<button className="btn">
									CREATE AN ACCOUNT
									<img src={arrow} className="image-fluid pl-2" />
								</button>
							</div>

							{/* <p className="sub-title">Payment Details </p>
							<div class="card-type">
								<label class="container">
									<input
										type="radio"
										hidden
										checked="checked"
										name="payradio"
									/>
									<span class="checkmark">
										<figure>
											<img src={visa} class="img-fluid" />
										</figure>
									</span>
								</label>
								<label class="container">
									<input type="radio" hidden name="payradio" />
									<span class="checkmark">
										<figure>
											<img src={google} class="img-fluid" />
										</figure>
									</span>
								</label>
								<label class="container">
									<input type="radio" hidden name="payradio" />
									<span class="checkmark">
										<figure>
											<img src={mastercard} class="img-fluid" />
										</figure>
									</span>
								</label>
								<label class="container">
									<input type="radio" hidden name="payradio" />
									<span class="checkmark">
										<figure>
											<img src={Apple} class="img-fluid" />
										</figure>
									</span>
								</label>
							</div>
							<button className="btn mt-5">
								SUBMIT DETAILS
								<img src={arrow} className="image-fluid pl-2" />
							</button> */}
							<p className="sub-title">Payment Details </p>
							<StripeForm styling={styling} paynow="SUBMIT" />
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Camping;
