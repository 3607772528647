import React from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Cart.css";
import { useNavigate } from "react-router-dom";
import { arrow, arrow2, cart1 } from "../../constant";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

function Cart() {
	const Navigate = useNavigate();
	const cartData = [
		{
			id: 1,
		},
		{
			id: 2,
		},
		{
			id: 3,
		},
		{
			id: 4,
		},
		{
			id: 5,
		},
	];

	const BootstrapTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.black,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.black,
		},
	}));

	const CheckoutHandler = (e) => {
		e.preventDefault();
		Navigate("/check-out");
	};
	return (
		<>
			<Header />
			<MainBanner name={"Shopping Cart"} />
			<section className="cart-page">
				<div className="container">
					<div className="container">
						<div className="row pb-5">
							<div className="col-lg-12 col-12">
								<div className="cart-table-wrapper">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th scope="col"></th>
													<th scope="col">Product Name</th>
													<th scope="col">Detail</th>
													<th scope="col">
														Delivery Date{" "}
														<BootstrapTooltip
															enterTouchDelay={0}
															title={
																<ul className="toaster_ul">
																	<li>
																		Winter (January - February) Southern & West
																		Coast States + HI
																	</li>
																	<br />
																	<li>
																		Early Spring (March - April) All Lower 48
																		States + HI
																	</li>
																	<br />
																	<li>
																		Late Spring (May - June) Northern & West
																		Coast States + AK
																	</li>
																	<br />
																	<li>
																		Fall (September - November) All Lower 48
																		States + HI
																	</li>
																</ul>
															}
														>
															<IconButton>
																<InfoIcon
																	style={{
																		fontSize: "17px",
																		background: "transparent",
																		color: "#fff",
																	}}
																/>
															</IconButton>
														</BootstrapTooltip>
													</th>
													<th scope="col">Price</th>
													<th scope="col">Quantity</th>
													<th scope="col">Sub Total</th>
													<th scope="col"></th>
												</tr>
											</thead>
											<tbody>
												{cartData?.map((item, index) => {
													return (
														<tr>
															<td className="pro-img">
																<div className="img-wrapper">
																	<img
																		src={cart1}
																		className="img-fluid"
																		alt=""
																	/>
																</div>
															</td>
															<td className="pro-name">Whisper Coneflower</td>
															<td className="pro-name main-card-control">
																Color: Green
																<br />
																<span>Leaf Type: Lorem</span>
															</td>
															<td className="pro-name">
																<select className="form-control">
																	<option>Select Delivery Month</option>
																	<option>Winter (January - February)</option>
																	<option>Early Spring (March - April)</option>
																	<option>Late Spring (May - June)</option>
																	<option>Fall (September - November)</option>
																</select>
															</td>

															<td className="total-price settotal-price">
																$ 49.00
															</td>
															<td>
																<div className="number">
																	<span className="minus">-</span>
																	<input type="text" value="01" />
																	<span className="plus">+</span>
																</div>
															</td>
															<td className="sub-total">$ 49.00</td>
															<td className="close-row">
																<button>
																	{" "}
																	<CloseIcon className="seticon" />
																</button>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
									{/* <div className='discount_code_btn'>
									<div className="discount-codes">
										<p>Discount codes</p>
										<div className="inputDiv">
											<input
												type="text"
												placeholder="Enter your coupon code if you have one."
											/>
											<button className="btn checkoutBtn">APPLY</button>
										</div>
									</div>
									<div className="button-group">
										<button className="btn continueBtn">
											Continue Shopping
										</button>
										<button className="btn clearBtn">Clear Cart</button>
									</div>
									</div> */}
									<div className="row">
										<div className="col-md-7">
											{/* <div className="dicount_section">
												<div className="row">
													<div className="col-md-4">
														<p>Discount codes</p>
													</div>
													<div className="col-md-8">
														<div className="inputDiv">
															<input
																type="text"
																placeholder="Enter your coupon code if you have one."
															/>
															<button className="btn checkoutBtn">APPLY</button>
														</div>
													</div>
												</div>
											</div> */}
										</div>
										<div className="col-md-5">
											<div className="button-group">
												<button className="btn continueBtn">
													Continue Shopping
												</button>
												<button className="btn clearBtn">Clear Cart</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6">
								<div className="cart-t-tal-wrapper">
									<div className="cart-content-wrapper">
										<ul>
											<li>
												<span className="property">Subtotal :</span>
												<span className="value">$ 49.00</span>
											</li>
											<li>
												<span className="property">
													Estimated Shipping Cost :
												</span>
												<span className="value">Free Shipping</span>
											</li>
										</ul>
									</div>
									<div className="cart-footer-wrapper">
										<ul>
											<li>
												<span className="property">TOTAL COST :</span>{" "}
												<span className="value">$ 700.15</span>{" "}
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="checkout-cont">
									{" "}
									<button
										className="btn checkoutBtn"
										onClick={(e) => CheckoutHandler(e)}
									>
										Continue To checkout &nbsp;&nbsp;
										<img src={arrow} className="image-fluid" alt="" />{" "}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Cart;
