import React, { useEffect, useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/FramersBooth.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { arrow } from "../../constant";
import Apple from "../../assets/img/booking/garder_town/apple.png";
import google from "../../assets/img/booking/garder_town/google.png";
// import paypal from "../../assets/img/booking/garder_town/paypal.png";
import mastercard from "../../assets/img/booking/garder_town/mastercard.png";
import visa from "../../assets/img/booking/garder_town/visacard.png";
import StripeForm from "../../Components/PaymentMethod/StripeForm";
import { dollar } from "../../constant";
import ActionButton from "../../Components/ActionBtn/ActionButton";
function FramersBooth() {
	const styling = {
		marginTop: "10px",
		width: "30%",
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<MainBanner name={"Framers Booth Space"} />
			<section className="Framers-page">
				<div className="container">
					<div>
						<div className="Book_range_center">
							<h2 className="main-Title">Book Farmer’s Booth Space </h2>
							<h3 className="priceRate">
								<img src={dollar} alt="" /> 250.00
							</h3>
						</div>
						{/* <h2 className="main-Title">Book Farmer’s Booth Space </h2> */}
						<p className="main-para">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.
						</p>
						<ActionButton />
						<div className="formContainer">
							<p className="sub-title">Booking Details</p>
							<div className="row">
								<div className="col-lg-6">
									<p className="sub-title-sm">Select the Date</p>
									<input type="date" />
								</div>
								<div className="col-lg-6">
									<p className="sub-title-sm">Select Check-in Time</p>
									<input type="time" />
								</div>

								<div className="col-lg-6">
									<p className="sub-title-sm">Select location on farm</p>
									<select>
										<option>Select</option>
										<option>Garder Town</option>
										<option>Garder Town</option>
									</select>
								</div>
							</div>

							<p className="sub-title">Personal Information </p>
							<div className="camping_btn">
								<button className="btn">
									SIGN IN
									<img src={arrow} className="image-fluid pl-2" />
								</button>
								<button className="btn secondbtn">
									CREATE AN ACCOUNT
									<img src={arrow} className="image-fluid pl-2" />
								</button>
							</div>

							<p className="sub-title">Payment Details </p>
							<StripeForm styling={styling} paynow="SUBMIT" />
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default FramersBooth;
