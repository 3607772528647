import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/Profile.css";
import SideBar from "../../Components/SideBar/SideBar";
import { Link } from "react-router-dom";
import TopBar from "../../Components/SideBar/TopBar";
import QAScanner from "../../Components/QAScanner/QAScanner";

function QACode() {
	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState("");
	const [uploadLoading, setUploadLoading] = useState(false);
	const handleImageUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		setUploadLoading(true);
	};
	return (
		<>
			<Header />

			{/* <!-- Universal Banner Sec End Here --> */}
			<section className="profile">
				<div className="container">
					<div className="row profile-row">
						<SideBar
							uploadLoading={uploadLoading}
							fileupload={fileupload}
							handleImageUpload={handleImageUpload}
						/>
						<div className="col-lg-9">
							<div className="tab-content" id="v-pills-tabContent">
								<div
									className="tab-pane fade active show"
									id="pills-home"
									role="tabpanel"
									aria-labelledby="pills-home-tab"
								>
									<TopBar />

									<div className="row">
										<div className="col-lg-12 ">
											<h3 className="form-heading heading-top-Scanner">
												Scan QR code
											</h3>
											<hr />
											<div>
												<div className="Scanner_div">
														<p className="Scanner_paragharaf">
															Duis aute irure dolar in reprehenderit in
															voluptate velit esse cillum dolore eu fugiat nulla
															pariatur. Excepteur sint occaecat cupidatat non
															proident, sunt in culpa qui officia deserunt
															mollit anim id est laborum.
														</p>
														<QAScanner />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default QACode;
