import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { profile } from "../../constant";

function SideBar(props) {
  const { uploadLoading, fileupload, handleImageUpload } = props;
  let currentUrl = window.location.href.split("/");
  console.log("currentUrl", currentUrl);
  return (
    <>
      <div className="col-lg-3">
        <div className="profile-tabs-wrapper">
          <div className="img-and-username-wrapper">
            <h2 className="mainTitle">My Profile</h2>
            <div className="profile-img">
              <figure>
                {uploadLoading ? (
                  <img src={fileupload} className="img-fluid" alt="" />
                ) : (
                  <img
                    src={`${
                      fileupload ? fileupload : `${profile}`
                    }`}
                    // src={userData?.profile_picture}
                    className="img-fluid"
                    alt=""
                  />
                )}

                <a href="#" className="chnage">
                  Change image
                </a>

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  multiple={false}
                  className="profile-upload"
                />
              </figure>
            </div>
            <div className="username mt-4">
              <h3 className="username">Everest Richards</h3>
            </div>
          
          </div>
         
        </div>
      </div>
    </>
  );
}

export default SideBar;
