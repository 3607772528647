import React, { useEffect, useRef } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Testimonial.css";
import TestimonialSlider from "./TestimonialSlider";
import MainBanner from "../../Components/Banner/MainBanner";

function Testimonial() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<MainBanner name={"Testimonials"} />
			<section className="imageContainer">
				<div className="container testimonial-page">
					<div className="row first-section">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<h6 className="tourists-title">
								Have a look At what our client Say
							</h6>
							<p className="testimonial-para">
								Nisl vel pretium lectus quam id leo. Odio ut enim blandit
								volutpat maecenas volutpat blandit. Nunc vel risus commodo
								viverra maecenas. Lacus luctus accumsan tortor posuere ac ut
								consequat.
							</p>
							<div className="col-lg-2"></div>
						</div>
					</div>
					<div className="row second-section">
						<div className="col-lg-12">
							<TestimonialSlider />
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Testimonial;
