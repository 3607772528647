import React from "react";
import Slider from "react-slick";
import { image1, image2 } from "../../constant";

function TestimonialSlider() {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-1"></div>
					<div className="col-lg-10 testimonial_set_btn">
						<Slider {...settings}>
							<div className="container">
								<div className="row">
									<div className="col-lg-5">
										<img
											src={image1}
											alt="testimonial"
											className="img-fluid testimonialslider"
										/>
									</div>
									<div className="col-lg-7 silder_set_div">
										<h3 className="productName">Whisper Coneflower</h3>
										<p className="maples">Our Customer</p>
										<p className="testimonial-para">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ut enim ad minim veniam, quis nostrud exercitation
											ullamco laboris nisi ut aliquip ex ea commodo consequat.
											Duis aute irure dolor in reprehenderit in voluptate velit
											esse cillum dolore eu fugiat nulla pariatur. Excepteur
											sint occaecat cupidatat non proident.
											<br />
											<br />
											At augue eget arcu dictum varius duis at consectetur.
											Molestie a iaculis at erat pellentesque adipiscing commodo
											elit at. Varius quam quisque id diam vel quam elementum.
											Sed ullamcorper morbi tincidunt ornare.
										</p>
									</div>
								</div>
							</div>
							<div className="container">
								<div className="row">
									<div className="col-lg-5">
										<img
											src={image2}
											alt="testimonial"
											className="img-fluid testimonialslider"
										/>
									</div>
									<div className="col-lg-7">
										<h3 className="productName">Whisper Coneflower</h3>
										<p className="maples">Our Customer</p>
										<p className="testimonial-para">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ut enim ad minim veniam, quis nostrud exercitation
											ullamco laboris nisi ut aliquip ex ea commodo consequat.
											Duis aute irure dolor in reprehenderit in voluptate velit
											esse cillum dolore eu fugiat nulla pariatur. Excepteur
											sint occaecat cupidatat non proident.
											<br />
											<br />
											At augue eget arcu dictum varius duis at consectetur.
											Molestie a iaculis at erat pellentesque adipiscing commodo
											elit at. Varius quam quisque id diam vel quam elementum.
											Sed ullamcorper morbi tincidunt ornare.
										</p>
									</div>
								</div>
							</div>
						</Slider>
					</div>
					<div className="col-lg-1"></div>
				</div>
			</div>
		</>
	);
}

export default TestimonialSlider;
