import React from "react";
import { Link } from "react-router-dom";

function TopBar() {
	let currentUrl = window.location.href.split("/");
	console.log("currentUrl", currentUrl);

	return (
		<div className="tabContainer">
			<Link
				to="/profile"
				className={`${
					currentUrl?.[3] === "profile" ? "nav-link active" : "nav-link"
				}`}
			>
				My Profile
			</Link>
			<Link
				to="/address"
				className={`${
					currentUrl?.[3] === "address" ? "nav-link active" : "nav-link"
				}`}
			>
				My Address
			</Link>
			<Link
				to="/my-orders"
				className={`${
					currentUrl?.[3] === "my-orders" ? "nav-link active" : "nav-link"
				}`}
			>
				My Orders
			</Link>
			<Link
				to="/my-bookings"
				className={`${
					currentUrl?.[3] === "my-bookings" ? "nav-link active" : "nav-link"
				}`}
			>
				My Bookings
			</Link>
			<Link
				to="/my-membership"
				className={`${
					currentUrl?.[3] === "my-membership" ? "nav-link active" : "nav-link"
				}`}
			>
				My Membership
			</Link>
			<Link
				to="/my-donations"
				className={`${
					currentUrl?.[3] === "my-donations" ? "nav-link active" : "nav-link"
				}`}
			>
				My Donations
			</Link>
			<Link
				to="/wishlist"
				className={`${
					currentUrl?.[3] === "wishlist" ? "nav-link active" : "nav-link"
				}`}
			>
				Wishlist
			</Link>
			<Link
				to="/scanner"
				className={`${
					currentUrl?.[3] === "scanner" ? "nav-link active" : "nav-link"
				}`}
			>
				QR Code
			</Link>
		</div>
	);
}

export default TopBar;
