import React, { useRef } from "react";
import { HomeContact, arrow3 } from "../../constant";

function HomeContactForm() {
	const ref = useRef();
	const setref = useRef();

	return (
		<>
			<section
				className="home-contact"
				style={{ zIndex: "9", position: "relative" }}
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-5  col-sm-12 animate__animated animate__backInLeft">
							<div className="img-cont lecture_Img">
								<img
									src={HomeContact}
									alt=""
									className="image-fluid home-contact-image"
								/>
							</div>
						</div>
						<div className="col-lg-7  col-sm-12 animate__animated animate__backInRight">
							<div className="head_title_contact">
								<h2 className="shadow-title shadow-title2">Book Now</h2>
								<h3 className="about-title">Book Your Visit in Our Farm</h3>
								<p className="para">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco.
								</p>

								<div className="row home-all-input">
									<div className="col-lg-6 ">
										<div>
											<input type="text" placeholder="Full Name" />
										</div>
									</div>
									<div className="col-lg-6 ">
										<div>
											<input type="text" placeholder="Email" />
										</div>
									</div>

									<div className="col-lg-6 ">
										<div>
											{/* <input type="date" placeholder="Date" /> */}
											{/* <input
												type="text"
												ref={setref}
												placeholder="Select Date"
												onChange={(e) => console.log(e.target.value)}
												onFocus={() => (setref.current.type = "date")}
												onBlur={() => (setref.current.type = "date")}
											/> */}
											<input type="date" className="date_icon" />
											{/* <select name="date" id="date">
												<option selected="">Select Date</option>
												<option value="saab">date</option>
												<option value="mercedes">date</option>
												<option value="audi">date</option>
											</select> */}
										</div>
									</div>
									<div className="col-lg-6">
										<div>
											{/* <input
												type="text"
												ref={ref}
												placeholder="Select Time"
												onChange={(e) => console.log(e.target.value)}
												onFocus={() => (ref.current.type = "time")}
												onBlur={() => (ref.current.type = "time")}
											/> */}
											<input
												type="time"
												className="time_icon"
												placeholder="Select Time"
											/>
											{/* <select name="date" id="date">
												<option selected="">Select Time</option>
												<option value="saab">Time</option>
												<option value="mercedes">Time</option>
												<option value="audi">Time</option>
											</select> */}
										</div>
									</div>
									<div className="col-lg-6 ">
										<div>
											<input type="text" placeholder="Member Under Age 6 " />
										</div>
									</div>
									<div className="col-lg-6 ">
										<div>
											<input type="text" placeholder="Members above Age 6" />
										</div>
									</div>

									<div className="col-lg-6">
										<div className="row mt-4 visit-input">
											<div className="col-lg-5  check-container">
												<input
													type="checkbox"
													id="vehicle1"
													name="vehicle1"
													value="Bike"
													className="tick"
												/>
												<p>Garden Visit </p>
											</div>
											<div className="col-lg-7 check-container">
												<input
													type="checkbox"
													id="vehicle1"
													name="vehicle1"
													value="Bike"
													className="tick"
												/>
												<p>Campsite Reservation </p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 ">
										<div className="btn-cont">
											<button className="btn BOOKNOW">
												&nbsp; $250.00 &nbsp; &nbsp; BOOK NOW &nbsp; &nbsp;{" "}
												<img src={arrow3} className="image-fluid" alt="" />{" "}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HomeContactForm;
